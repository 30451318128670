import { QueryFunctionContext } from '@tanstack/react-query';
import { ResponseSuccess } from 'types/common.types';
import { GrantUserAcl } from 'types/super-account.types';

import accountServiceRequest from './account-service.request';

export default {
  approveSuperAccount: async (uuid: string): Promise<ResponseSuccess> => {
    try {
      const res = await accountServiceRequest.post('/api/v1/agent-grants/approve/' + uuid);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getNotificationDetail: async ({
    queryKey,
  }: QueryFunctionContext<[string, string]>): Promise<GrantUserAcl> => {
    const [, uuid] = queryKey;
    const response = await accountServiceRequest.get(
      '/api/v1/agent-grants/client/notification-detail/' + uuid
    );
    return response.data.data;
  },
  declineSuperAccount: async (uuid: string): Promise<ResponseSuccess> => {
    try {
      const res = await accountServiceRequest.post('api/v1/agent-grants/decline/' + uuid);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
};
