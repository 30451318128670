import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Input } from 'components/forms';
import { Title } from 'components/typography';
import { alert } from 'constant/messages';
import { useLiveQuery } from 'dexie-react-hooks';
import { Form, Formik } from 'formik';
import { useNotification } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React from 'react';
import Select from 'react-select';
import { selectCustomer, setContactToOrder } from 'redux/reducer/sales';
import commonRequest from 'services/http/common.request';
import shipmentRequest from 'services/http/shipment.request';
import contactCategory from 'services/indexdb/contact-category';
import customers from 'services/indexdb/customers';
import customTheme from 'theme/select-theme';
import { IContactCategory, ICustomerInfo, ICustomerInfoInput } from 'types/common.types';
import { IProvince, IRegionList } from 'types/shipment.types';
import { debounce } from 'utils';

interface ModalCustomerProps {
  isOpen: boolean;
  isSetCustomer?: boolean;
  onClose: () => void;
  onSubmit: (value?: ICustomerInfoInput) => void;
  email?: string;
  addFromEmail?: boolean;
}

const initialValues: ICustomerInfoInput = {
  contact_name: '',
  contact_type: 0,
  contact_id: 0,
  email: '',
  phone: '',
  s_province: '',
  s_province_id: '',
  s_area: '',
  s_city: '',
  s_city_id: '',
  s_post_code: '',
  s_district_id: '',
  s_subdistrict: '',
  s_subdistrict_id: '',
  s_address: '',
  b_address: '',
  b_area: '',
  b_city: '',
  b_province: '',
  b_post_code: '',
  b_subdistrict: '',
  b_province_id: '',
  b_city_id: '',
  b_district_id: '',
  b_subdistrict_id: '',
  location_id: 0,
  category_id: -1,
  country_id: 45,
  dob: null,
  contact_source: null,
  source_detail: null,
};

const CONTACT_SOURCE = [
  { value: 1, label: 'Marketplace' },
  { value: 2, label: 'Social Media' },
];

const ModalCustomer: React.FC<ModalCustomerProps> = ({
  isOpen,
  isSetCustomer,
  onClose,
  onSubmit,
  email,
  addFromEmail,
}: ModalCustomerProps) => {
  const dispatch = useAppDispatch();

  const location = useAppSelector((state) => state.register.location);
  const authSelector = useAppSelector((state) => state.auth);
  const isOnline = useAppSelector((state) => state.register.isOnline);

  const [searchCountry, setSearchCountry] = React.useState<string>('');
  const [regionList, setRegionList] = React.useState<IRegionList | null>(null);
  const [formValue, setFormValue] = React.useState<ICustomerInfoInput>(initialValues);

  const { notification } = useNotification();

  const { data: contactCountryList } = useQuery({
    queryKey: ['country-list', { q: searchCountry }],
    queryFn: commonRequest.getContactCountryList,
    enabled: isOnline && isOpen,
    select: (data) => data.data.map((item) => ({ value: item.country_id, label: item.country_name })),
  });

  const mutateCreateCustomer = useMutation({
    mutationKey: ['create-customer'],
    mutationFn: commonRequest.createCustomer,
  });

  const mapRegionList =
    regionList && regionList.data.length > 0
      ? ((regionList?.data.map((i) => ({
          value: i,
          label: i.name,
        })) || []) as unknown as IProvince[])
      : [];

  const validate = (values: ICustomerInfoInput) => {
    const errors: Record<string, string> = {};
    if (!values.contact_name) {
      errors.contact_name = 'Harap masukan nama customer';
    }

    if (!values.phone) {
      errors.number = 'Harap masukan nomor telepon.';
    }

    return errors;
  };

  const listContactCategory = useLiveQuery<IContactCategory[]>(() => {
    return contactCategory.get();
  }, []);

  const optionCategory = listContactCategory?.map((category) => {
    return {
      value: category.category_id,
      label: category.category_code.concat(' - ', category.category_name),
    };
  });

  const mutateRegions = async (e: string) => {
    if (isOnline) {
      const region = await shipmentRequest.getRegionList(e);
      setRegionList(region);
    }
  };

  const inputChangeRegion = debounce(async (e: string) => {
    if (e) mutateRegions(e);
  }, 500);

  // save customer into server
  // and then save customer into local
  const handleSaveCustomer = async (values: ICustomerInfoInput) => {
    const findCategory =
      listContactCategory &&
      listContactCategory.find((item) => Number(item.category_id) === values.category_id);
    const categoryDisplay = findCategory
      ? findCategory.category_code.concat(' - ', findCategory.category_name)
      : '';

    const customerData: ICustomerInfoInput = {
      ...values,
      contact_id: 0,
      category_id: values.category_id || -1,
      category_display: categoryDisplay,
      b_address: values.s_address,
    };

    if (isOnline) {
      await mutateCreateCustomer.mutateAsync(customerData, {
        onSuccess: (res) => {
          customerData.contact_id = res?.contact_id ?? 0;
        },
        onError: (error) => {
          let message = 'Unknown Error';
          if (error instanceof Error) message = error.message;
          if (error instanceof AxiosError) message = error.response?.data.code;
          return notification('', message, 'error', 3000);
        },
      });
    }

    const customerDataLocal: ICustomerInfo = {
      ...customerData,
      is_dropshipper: false,
      is_loyalty_member: false,
      store_credit: '0.0000',
      category_name: findCategory?.category_name,
    };

    const existCustommerByPhone = await customers.findOneByPhone(customerDataLocal.phone ?? '');
    if (existCustommerByPhone) {
      return notification('', alert.error_duplicate_contact, 'error', 3000);
    }

    if (customerDataLocal.email) {
      const existCustommerByEmail = await customers.findOneByEmail(customerDataLocal.email ?? '');
      if (existCustommerByEmail) return notification('', alert.error_duplicate_contact, 'error', 3000);
    }
    await customers.add(customerDataLocal);

    dispatch(setContactToOrder(customerData));
    if (isSetCustomer) {
      dispatch(selectCustomer(customerDataLocal));
    }
    onSubmit(customerData);
  };

  React.useEffect(() => {
    setFormValue({
      ...initialValues,
      email: email ?? '',
      location_id: location?.location_id ?? 0,
    });
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size='2xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader> Buat Pelanggan</ModalHeader>
        <Formik
          initialValues={formValue}
          validate={validate}
          onSubmit={handleSaveCustomer}
          key={JSON.stringify(formValue)}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit, touched, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <ModalBody
                borderTop='1px'
                borderBottom='1px'
                borderColor='gray.200'
                py={4}
                overflowY='auto'
                maxH='calc(100vh - 260px)'
              >
                <Title fontSize='16px' mb={4}>
                  Info Umum
                </Title>
                <VStack spacing={4}>
                  <FormControl
                    isInvalid={Boolean(errors.contact_name && touched.contact_name)}
                    display='flex'
                    className='space-x-[5px]'
                    alignItems='center'
                  >
                    <FormLabel w='150px' htmlFor='contact_name' fontSize='14px'>
                      Nama
                    </FormLabel>
                    <VStack w='full' alignItems='flex-start'>
                      <Input
                        id='contact_name'
                        name='contact_name'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.contact_name}
                      />
                      <FormErrorMessage>{touched.contact_name && errors.contact_name}</FormErrorMessage>
                    </VStack>
                  </FormControl>
                  <FormControl
                    isInvalid={Boolean(errors.phone && touched.phone)}
                    display='flex'
                    className='space-x-[5px]'
                    alignItems='center'
                  >
                    <FormLabel w='150px' htmlFor='phone' fontSize='14px'>
                      No Telepon
                    </FormLabel>
                    <VStack w='full' alignItems='flex-start'>
                      <Input
                        id='phone'
                        name='phone'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.phone as string}
                      />
                      <FormErrorMessage>{touched.phone && errors.phone}</FormErrorMessage>
                    </VStack>
                  </FormControl>
                  <FormControl display='flex' className='space-x-[5px]' alignItems='center'>
                    <FormLabel w='150px' htmlFor='email' fontSize='14px'>
                      Email
                    </FormLabel>
                    <VStack w='full' alignItems='flex-start'>
                      <Input
                        id='email'
                        name='email'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email as string}
                        defaultValue={email}
                      />
                    </VStack>
                  </FormControl>
                  <FormControl display='flex' className='space-x-[5px]' alignItems='center'>
                    <FormLabel w='150px' htmlFor='kategori' fontSize='14px'>
                      Kategori Pelanggan
                    </FormLabel>
                    <Box w='full'>
                      <Select
                        placeholder='--Pilih Kategori--'
                        options={optionCategory || []}
                        styles={customTheme}
                        menuPlacement='auto'
                        onChange={(e: any) => setFieldValue('category_id', Number(e?.value))}
                        value={
                          optionCategory
                            ? optionCategory.filter((option: any) => option.value === values.category_id)
                            : null
                        }
                      />
                    </Box>
                  </FormControl>
                  <FormControl display='flex' className='space-x-[5px]' alignItems='center'>
                    <FormLabel w='150px' htmlFor='contact_source' fontSize='14px'>
                      Sumber
                    </FormLabel>
                    <Box w='full'>
                      <Select
                        placeholder='--Pilih Sumber--'
                        options={CONTACT_SOURCE || []}
                        styles={customTheme}
                        menuPlacement='auto'
                        onChange={(e) => setFieldValue('contact_source', Number(e?.value))}
                        value={
                          CONTACT_SOURCE
                            ? CONTACT_SOURCE.filter((option) => option.value === values.contact_source)
                            : null
                        }
                      />
                    </Box>
                  </FormControl>
                  <FormControl display='flex' className='space-x-[5px]' alignItems='center'>
                    <FormLabel w='150px' htmlFor='source_detail' fontSize='14px'>
                      Detail Sumber
                    </FormLabel>
                    <VStack w='full' alignItems='flex-start'>
                      <Input
                        id='source_detail'
                        name='source_detail'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.source_detail as string}
                      />
                    </VStack>
                  </FormControl>
                  {authSelector.is_wms_migrated && isOnline && (
                    <FormControl display='flex' className='space-x-[5px]' alignItems='center'>
                      <FormLabel w='150px' htmlFor='s_are' fontSize='14px'>
                        Kewarganegaraan
                      </FormLabel>
                      <Box w='full'>
                        <Select
                          placeholder='--Pilih Kewarganegaraan--'
                          options={contactCountryList || []}
                          styles={customTheme}
                          menuPlacement='auto'
                          onChange={(e: any) => {
                            setFieldValue('country_id', String(e?.value));
                          }}
                          value={
                            contactCountryList
                              ? contactCountryList.filter(
                                  (option: any) => String(option.value) === String(values.country_id)
                                )
                              : null
                          }
                          onInputChange={(e) => setSearchCountry(e)}
                        />
                      </Box>
                    </FormControl>
                  )}
                  {authSelector.is_wms_migrated && (
                    <FormControl display='flex' className='space-x-[5px]' alignItems='center'>
                      <FormLabel w='150px' htmlFor='s_are' fontSize='14px'>
                        Tanggal Lahir
                      </FormLabel>
                      <Box w='full'>
                        <Input
                          placeholder='Select Date and Time'
                          size='md'
                          type='date'
                          onChange={(e) => setFieldValue('dob', String(e.target.value))}
                          value={String(values.dob)}
                        />
                      </Box>
                    </FormControl>
                  )}
                </VStack>
                {isOnline && (
                  <Title fontSize='16px' mt='32px' mb='27px'>
                    Alamat (Opsional)
                  </Title>
                )}
                {isOnline && (
                  <VStack spacing={4}>
                    <FormControl display='flex' className='space-x-[5px]' alignItems='center'>
                      <FormLabel w='150px' htmlFor='s_are' fontSize='14px'>
                        Alamat
                      </FormLabel>
                      <Box w='full'>
                        <Select
                          inputId='s_province_id'
                          placeholder='--Pilih Alamat--'
                          options={mapRegionList || []}
                          menuPlacement='auto'
                          onChange={(e: any) => {
                            const province = e?.value ? e?.value.area_id.substring(0, 2) : '';
                            const city = e?.value ? e?.value.area_id.substring(0, 4) : '';
                            const district = e?.value ? e?.value.area_id.substring(0, 6) : '';
                            setFieldValue('s_province', e?.value.province);
                            setFieldValue('s_province_id', province);
                            setFieldValue('s_city', e?.value.city);
                            setFieldValue('s_city_id', city);
                            setFieldValue('s_district_id', district);
                            setFieldValue('s_area', e?.value.district);
                            setFieldValue('s_subdistrict_id', e?.value.area_id);
                            setFieldValue('s_subdistrict', e?.value.area);
                            setFieldValue('s_post_code', e?.value.zipcode);
                            setFieldValue('b_area', e?.value.district);
                            setFieldValue('b_city', e?.value.city);
                            setFieldValue('b_province', e?.value.province);
                            setFieldValue('b_post_code', e?.value.zipcode);
                            setFieldValue('b_subdistrict', e?.value.area);
                            setFieldValue('b_province_id', province);
                            setFieldValue('b_city_id', city);
                            setFieldValue('b_district_id', district);
                            setFieldValue('b_subdistrict_id', e?.value.area_id);
                          }}
                          onInputChange={(e) => {
                            if (e) inputChangeRegion(e);
                          }}
                          onBlur={handleBlur}
                          value={mapRegionList?.filter(
                            (option: any) => option.value.area_id === values.s_subdistrict_id
                          )}
                          isDisabled={!isOnline}
                        />
                      </Box>
                    </FormControl>
                    <FormControl display='flex' className='space-x-[5px]' alignItems='center'>
                      <FormLabel w='150px' htmlFor='address' fontSize='14px'>
                        Alamat Lengkap
                      </FormLabel>
                      <Textarea
                        id='address'
                        name='s_address'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.s_address as string}
                      />
                    </FormControl>
                  </VStack>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  isDisabled={mutateCreateCustomer.isPending || !values.contact_name || !values.phone}
                  variant='primary'
                  type='submit'
                  isLoading={mutateCreateCustomer.isPending}
                  loadingText='Menyimpan..'
                >
                  {addFromEmail ? 'Kirim & Simpan' : 'Simpan'}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default ModalCustomer;
