import invoiceRequest from 'services/http/invoice.request';
import { IInvoice, IReportInvoice } from 'types/invoice';

interface IUseInvoice {
  getReportInvoice: (qs: string) => Promise<IReportInvoice>;
  sendInvoice: (salesorder_id: number) => Promise<IInvoice>;
}

const useInvoice = (): IUseInvoice => {
  const sendInvoice = async (salesorder_id: number): Promise<IInvoice> => {
    try {
      const response = await invoiceRequest.sendInvoice(salesorder_id);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  const getReportInvoice = async (qs: string): Promise<IReportInvoice> => {
    try {
      const response = await invoiceRequest.getInvoice(qs);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return {
    sendInvoice,
    getReportInvoice,
  };
};

export default useInvoice;
