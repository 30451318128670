import { Flex, Text, VStack } from '@chakra-ui/react';
import { SubTitle } from 'components/typography';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { IItemCart } from 'types/products.types';
import { currencyFormat, getDiscountItem } from 'utils';

interface IProps {
  item: IItemCart;
  amountPromotion: number;
  detailBundleItem: boolean;
  detailItemPromotion: boolean;
}

const CardItemPromotion: React.FC<IProps> = ({
  item,
  amountPromotion,
  detailBundleItem,
  detailItemPromotion,
}: IProps) => {
  const { listPromotionsItems } = useAppSelector((state) => state.sales);
  const totalDiscount = React.useMemo(() => {
    return getDiscountItem(item);
  }, [item]);

  return (
    <Flex
      border='1px'
      borderColor='jubelio.grey'
      p={5}
      rounded='4px'
      bg='jubelio.grey100'
      width='full'
      position='relative'
      mt={4}
    >
      <VStack spacing={2} w='full' alignItems='start'>
        {detailItemPromotion && (
          <>
            <SubTitle fontSize='12px'>Detail Potongan</SubTitle>
            {(item.discount_amount > 0 || item.discount_percent > 0) && (
              <Flex
                justifyContent='space-between'
                className='space-x-2'
                mb='5px'
                position='relative'
                w='full'
              >
                <Text fontSize='14px'>Diskon Manual</Text>
                <Text fontSize='14px'>
                  {item.isDiscountPercent
                    ? `${item.discount_percent}% ( ${currencyFormat(totalDiscount - item.pos_slash_price)} )`
                    : currencyFormat(item.discount_amount)}
                </Text>
              </Flex>
            )}
            {item.pos_slash_price > 0 && (
              <Flex
                justifyContent='space-between'
                className='space-x-2'
                mb='5px'
                position='relative'
                w='full'
              >
                <Text fontSize='14px'>Harga Coret</Text>
                <Text fontSize='14px'>{currencyFormat(item.pos_slash_price)}</Text>
              </Flex>
            )}
            {Number(amountPromotion) > 0 &&
              listPromotionsItems.map((promo, index) => {
                const value = promo.itemsDiscountDetails[`p_${item.item_id}`];
                return (
                  <Flex
                    key={index}
                    justifyContent='space-between'
                    className='space-x-2'
                    mb='5px'
                    position='relative'
                    w='full'
                  >
                    <Text fontSize='14px'>Promosi {promo.rules.promotion_name}</Text>
                    <Text fontSize='14px'>{currencyFormat(value)}</Text>
                  </Flex>
                );
              })}
          </>
        )}
        {detailBundleItem && (
          <>
            <SubTitle fontSize='12px'>Detail Bundle</SubTitle>
            {item.bundle_child?.map((bundle, idx) => (
              <Flex
                justifyContent='space-between'
                className='space-x-2'
                mb='5px'
                position='relative'
                w='full'
              >
                <Text fontSize='14px'>
                  {idx + 1}. {bundle.item_name} ( x<strong>{bundle.qty}</strong> )
                </Text>
              </Flex>
            ))}
          </>
        )}
      </VStack>
    </Flex>
  );
};

export default CardItemPromotion;
