import axios from 'axios';
import config from 'constant';

export const AccountServiceAPI = config.ACCOUNT_SERVICE_API_URL;

const accountServiceRequest = axios.create({
  baseURL: AccountServiceAPI,
});

accountServiceRequest.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default accountServiceRequest;
