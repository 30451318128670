import {
  Accordion,
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import AccordionAccount from 'components/accordion/AccordionAccount';
import BadgeError from 'components/badge/BadgeError';
import { CardMain } from 'components/card';
import { Title } from 'components/typography';
import { useNotification } from 'hooks';
import React from 'react';
import { useParams } from 'react-router-dom';
import superAccountRequest from 'services/http/super-account.request';
import { IAllOptions, IRoles } from 'types/super-account.types';
import { calculateTimeDifference } from 'utils';
import {
  optionsFinance,
  optionsInventory,
  optionsKatalog,
  optionsPOS,
  optionsPurchase,
  optionsSales,
  optionsSystem,
  optionsWarehouse,
} from 'utils/constant';

const ACLdisplay = () => {
  const { uuid } = useParams();
  const { notification } = useNotification();
  const { data: grantAccess, refetch } = useQuery({
    queryKey: ['detailRequest', uuid ?? ''],
    queryFn: superAccountRequest.getNotificationDetail,
    enabled: !!uuid,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [action, setAction] = React.useState('');
  const [allOptions, setAllOptions] = React.useState<IAllOptions>({
    optionsSales: [...optionsSales],
    optionsSystem: [...optionsSystem],
    optionsPurchase: [...optionsPurchase],
    optionsFinance: [...optionsFinance],
    optionsInventory: [...optionsInventory],
    optionsPOS: [...optionsPOS],
    optionsKatalog: [...optionsKatalog],
    optionsWarehouse: [...optionsWarehouse],
  });

  const handleApprove = () => {
    setAction('approve');
    onOpen();
  };

  const handleCancel = () => {
    setAction('cancel');
    onOpen();
  };

  const mutateApprove = useMutation({
    mutationKey: ['approve-super-account'],
    mutationFn: superAccountRequest.approveSuperAccount,
  });

  const mutateDecline = useMutation({
    mutationKey: ['decline-super-account'],
    mutationFn: superAccountRequest.declineSuperAccount,
  });

  const approve = (uuid: string) => {
    mutateApprove.mutate(uuid, {
      onSuccess: () => {
        notification('', 'Permintaan berhasil disetujui', 'error', 3000);
        refetch();
      },
      onError: (error) => {
        let message = 'Unknown Error';
        if (error instanceof Error) message = error.message;
        if (error instanceof AxiosError) message = error.response?.data.code;
        notification('', message, 'error', 3000);
      },
    });
  };

  const decline = (uuid: string) => {
    mutateDecline.mutate(uuid, {
      onSuccess: () => {
        notification('', 'Permintaan berhasil ditolak', 'error', 3000);
        refetch();
      },
      onError: (error) => {
        let message = 'Unknown Error';
        if (error instanceof Error) message = error.message;
        if (error instanceof AxiosError) message = error.response?.data.code;
        notification('', message, 'error', 3000);
      },
    });
  };

  const handleConfirmation = async (confirmed: boolean) => {
    if (!uuid) return notification('', 'UUID tidak ditemukan', 'error', 3000);
    if (confirmed) approve(uuid);
    else decline(uuid);
    onClose();
  };

  const mapAccessData = (options: string, acls: any[]) => {
    const newOptions = allOptions[options].map((option) => {
      if (option.access) {
        option.access.forEach((accessItem: any) => {
          if (acls.some((acl) => acl.id === accessItem.value)) {
            accessItem.checked = true;
          }
        });
      }

      if (option.child) {
        option.child.forEach((childOption: any) => {
          childOption.access.forEach((childAccessItem: any) => {
            if (acls.some((acl) => acl.id === childAccessItem.value)) {
              childAccessItem.checked = true;
            }
          });
        });
      }

      if (option.value && !option.access && !option.child) {
        if (acls.some((acl) => acl.id === option.value)) {
          option.checked = true;
        }
      }

      return option;
    });

    setAllOptions({
      ...allOptions,
      [options]: newOptions,
    });
  };

  React.useEffect(() => {
    if (grantAccess) {
      mapAccessData('optionsSystem', grantAccess.setting_acls);
      mapAccessData('optionsFinance', grantAccess.finance_acls);
      mapAccessData('optionsSales', grantAccess.sales_acls);
      mapAccessData('optionsPurchase', grantAccess.purchase_acls);
      mapAccessData('optionsInventory', grantAccess.inventory_acls);
      mapAccessData('optionsPOS', grantAccess.pos_acls);
      mapAccessData('optionsPOS', grantAccess.pos_acls_v1);
      mapAccessData('optionsKatalog', grantAccess.catalog_acls);
      mapAccessData('optionsWarehouse', grantAccess.warehouse_acls);
    }
  }, [grantAccess]);

  return (
    <Box h='full'>
      {grantAccess && (
        <>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Confirm Action</ModalHeader>
              <ModalBody>
                <Text>Are you sure you want to {action} this request?</Text>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme='green' mr={3} onClick={() => handleConfirmation(true)}>
                  Yes
                </Button>
                <Button colorScheme='gray' onClick={() => handleConfirmation(false)}>
                  No
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <CardMain flexDirection='column' justifyContent='center' overflowY='auto' position='relative'>
            <Box maxW='900px' w='full' px={3} py={8} position='relative'>
              <HStack className='flex flex-col text-left'>
                <Title fontSize={['18px', '18px', '21px', '22px', '24px']}>Hak Akses</Title>
                {grantAccess.status === 'approve' ? (
                  <BadgeError colorScheme='green'>Approve By: {grantAccess.approved_by}</BadgeError>
                ) : grantAccess.status === 'decline' ? (
                  <BadgeError colorScheme='red'>Decline By: {grantAccess.approved_by}</BadgeError>
                ) : null}
              </HStack>
              <Box alignItems={'center'} mb={1} gap={1} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Text>Permintaan Waktu Kadaluwarsa:</Text>
                {grantAccess?.expiration_date ? (
                  <Box>
                    {calculateTimeDifference(
                      grantAccess?.approved_at ? grantAccess?.approved_at : grantAccess?.created_at,
                      grantAccess?.expiration_date
                    )}
                  </Box>
                ) : (
                  <Box>No expiration date</Box>
                )}
              </Box>

              <Box mb={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Flex alignItems='center' mb={2}>
                  <Text>Peran Pengguna : &nbsp;</Text>
                  <Wrap spacing={2}>
                    {grantAccess?.roles.map((item: IRoles) => (
                      <WrapItem key={item.role_name}>
                        <Badge
                          variant='outline'
                          colorScheme='red'
                          px={3}
                          py={1}
                          borderRadius='md'
                          fontWeight='medium'
                        >
                          {item.role_name}
                        </Badge>
                      </WrapItem>
                    ))}
                  </Wrap>
                </Flex>
              </Box>

              <div className='my-3 h-1 bg-gray-100' />
              <Box style={{ overflowY: 'auto', maxHeight: '450px' }}>
                <Accordion defaultIndex={[0]} allowMultiple w='full'>
                  <AccordionAccount options={optionsSystem} title='System' />
                  <AccordionAccount options={optionsKatalog} title='Katalog' />
                  <AccordionAccount options={optionsInventory} title='Persediaan' />
                  <AccordionAccount options={optionsSales} title='Penjualan' />
                  <AccordionAccount options={optionsPurchase} title='Pembelian' />
                  <AccordionAccount options={optionsWarehouse} title='Gudang' />
                  <AccordionAccount options={optionsFinance} title='Keuangan' />
                  <AccordionAccount options={optionsPOS} title='Jubelio POS' />
                </Accordion>
              </Box>
              <div className='flex flex-col items-center text-left'>
                {grantAccess.status === 'pending' && (
                  <HStack className='m-3 gap-3'>
                    <Button
                      variant={'primary'}
                      size='md'
                      style={{ fontSize: 14 }}
                      w='50%'
                      onClick={handleApprove}
                    >
                      Setujui
                    </Button>
                    <Button
                      variant={'outline'}
                      size='md'
                      w='50%'
                      onClick={handleCancel}
                      style={{ fontSize: 14 }}
                    >
                      Tolak
                    </Button>
                  </HStack>
                )}
              </div>
            </Box>
          </CardMain>
        </>
      )}
    </Box>
  );
};

export default ACLdisplay;
