import { ICustomerInfo } from 'types/common.types';

import { db } from './connection';

export default {
  /**
   * save customer info to IndexDB
   * @param customer
   * @returns {Promise<boolean>}
   * @example await customers.add(customerInfo);
   */
  add: async (customer: ICustomerInfo): Promise<boolean> => {
    try {
      await db.customer.add(customer);
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  bulkAdd: async (customers: ICustomerInfo[]): Promise<number> => {
    try {
      return db.customer.bulkAdd(customers);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  /**
   * clear all customers info from IndexDB
   * @returns {Promise<void>}
   * @example await customers.clear();
   */
  clear: async (): Promise<void> => {
    return db.customer.clear();
  },

  /**
   * find customer info by id
   * @param id <number>
   * @returns {Promise<ICustomerInfo>} customer info object or null if not found or error
   * @example await customers.find(1);
   */
  findOne: async (id: string): Promise<ICustomerInfo | undefined> => {
    const res = db.customer.where('customer_id').equals(id).first();
    return res;
  },

  /**
   * find customer info by email
   * @param email <string>
   * @returns {Promise<ICustomerInfo>} customer info object or null if not found or error
   * @example await customers.find(1);
   */
  findOneByEmail: async (email: string): Promise<ICustomerInfo | undefined> => {
    const res = db.customer.where('email').equals(email).first();
    return res;
  },

  /**
   * find customer info by phone
   * @param phone <string>
   * @returns {Promise<ICustomerInfo>} customer info object or null if not found or error
   * @example await customers.find(1);
   */
  findOneByPhone: async (phone: string): Promise<ICustomerInfo | undefined> => {
    const res = db.customer.where('phone').equals(phone).first();
    return res;
  },

  /**
   * delete customer info by id
   * @param id <number>
   * @returns {Promise<boolean>} true if deleted or false if not found or error
   * @example await customers.delete(1)
   */
  delete: async (id: number): Promise<void> => {
    return db.customer.delete(id);
  },

  /**
   * get all customers info from IndexDB
   * @returns {Promise<ICustomerInfo[]>}
   * @example await customers.get();
   */
  get: async (): Promise<ICustomerInfo[]> => {
    return db.customer.toArray();
  },
  filter: async (keyword: string): Promise<ICustomerInfo[]> => {
    return db.customer
      .filter((customer) => {
        const search = new RegExp(keyword.toLowerCase());
        return (
          search.test(customer.contact_name ? customer.contact_name.toLowerCase() : '') ||
          search.test(customer.email ? customer.email.toLowerCase() : '') ||
          search.test(customer.phone ? customer.phone.toLowerCase() : '')
        );
      })
      .toArray();
  },
  getUnsync: async (): Promise<Promise<ICustomerInfo[]>> => {
    return db.customer.where('contact_id').equals(0).toArray();
  },
};
