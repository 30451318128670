import { CloseIcon, CopyIcon, EmailIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { Button, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { MoreIcon, PrintIcon } from 'components/icons';
import config from 'constant';
import { app } from 'constant/messages';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { DetailOrderTransaction } from 'types';

interface Props {
  detailOrder: DetailOrderTransaction;
  tabsIndex: number;
  openPaymentReference: (amount: number) => void;
  handlePrint: () => void;
  handleInvoice: () => void;
  onOpenEmail: () => void;
  disableButtonSendEmail: boolean;
  disableButtonCancel: boolean;
  onClickCancel: () => void;
  handleContinuePayment: (detailOrder: DetailOrderTransaction) => void;
  handleReturnOrder: (detailOrder: DetailOrderTransaction) => void;
  onClickRepeatOrder: () => void;
}

const ButtonDetailHistory: React.FC<Props> = ({
  detailOrder,
  tabsIndex,
  openPaymentReference,
  handlePrint,
  handleInvoice,
  onOpenEmail,
  disableButtonSendEmail,
  disableButtonCancel,
  onClickCancel,
  handleContinuePayment,
  handleReturnOrder,
  onClickRepeatOrder,
}: Props) => {
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const settings = useAppSelector((state) => state.commons.settings);

  const findQrisNoRef = (detailOrder: DetailOrderTransaction): boolean => {
    if (detailOrder.payments) {
      const qrisNoref = detailOrder.payments.find(
        (element) =>
          (element.no_ref === null ||
            element.no_ref === undefined ||
            element.no_ref === '' ||
            element.no_ref === app.noref_manual_complete_xendit) &&
          element.payment_id === config.QRIS_DYNAMIC
      );
      if (qrisNoref) return true;
    }
    return false;
  };

  return (
    <HStack>
      {/* TODO: Refactoring this and create new component for this menu*/}
      {((tabsIndex === 0 && detailOrder.is_return !== 1 && !detailOrder.is_canceled) || tabsIndex === 1) && (
        <Menu>
          <MenuButton as={IconButton} aria-label='Options' icon={<MoreIcon />} variant='outline' />
          <MenuList>
            {findQrisNoRef(detailOrder) && (
              <MenuItem
                icon={<InfoOutlineIcon color='jubelio.grey200' width={3.5} height={3.5} />}
                onClick={() => {
                  openPaymentReference(Number(detailOrder.grand_total));
                }}
                isDisabled={!isOnline}
              >
                Cek Pembayaran QRIS
              </MenuItem>
            )}
            {settings.allow_reprint_receipt && (
              <MenuItem icon={<PrintIcon />} onClick={handlePrint}>
                Cetak Struk
              </MenuItem>
            )}
            {tabsIndex === 0 && isOnline && (
              <MenuItem icon={<PrintIcon />} onClick={handleInvoice}>
                Cetak Faktur
              </MenuItem>
            )}
            {settings.allow_receipt_email && (
              <MenuItem
                icon={<EmailIcon color='jubelio.grey200' width={3.5} height={3.5} />}
                onClick={onOpenEmail}
                isDisabled={disableButtonSendEmail}
              >
                Kirim Email
              </MenuItem>
            )}
            {settings.allow_cancel && tabsIndex === 0 && (
              <MenuItem
                icon={<CloseIcon color='jubelio.grey200' width={3.5} height={2.5} />}
                isDisabled={disableButtonCancel}
                onClick={onClickCancel}
              >
                Batalkan Pesanan
              </MenuItem>
            )}
            {tabsIndex === 0 && (
              <MenuItem
                icon={<CopyIcon color='jubelio.grey200' width={3.5} height={3.5} />}
                onClick={onClickRepeatOrder}
              >
                Pesan Ulang
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      )}
      {(detailOrder.pos_is_unpaid === true || Number(detailOrder.pos_is_unpaid) === 1) && (
        <Button
          variant='outline'
          size='md'
          w='full'
          onClick={() => handleContinuePayment(detailOrder)}
          isDisabled={detailOrder.is_canceled}
        >
          Lakukan Pembayaran
        </Button>
      )}
      {settings.allow_return && detailOrder.is_return !== 1 && !detailOrder.is_canceled && (
        <Button
          variant='outline'
          size='md'
          w='full'
          onClick={() => handleReturnOrder(detailOrder)}
          disabled={
            detailOrder.is_canceled ||
            detailOrder.pos_is_unpaid === true ||
            Number(detailOrder.pos_is_unpaid) === 1
          }
          isDisabled={
            detailOrder.is_canceled ||
            detailOrder.pos_is_unpaid === true ||
            Number(detailOrder.pos_is_unpaid) === 1
          }
        >
          Retur
        </Button>
      )}
      {(detailOrder.is_return === 1 || detailOrder.is_canceled) && tabsIndex === 0 && (
        <>
          {isOnline && !detailOrder.is_canceled && (
            <Menu>
              <MenuButton as={IconButton} aria-label='Options' icon={<MoreIcon />} variant='outline' />
              <MenuList>
                <MenuItem icon={<PrintIcon />} onClick={handleInvoice}>
                  Cetak Faktur
                </MenuItem>
              </MenuList>
            </Menu>
          )}
          <Button variant='outline' size='md' w='full' onClick={onClickRepeatOrder}>
            Pesan Ulang
          </Button>
          {settings.allow_reprint_receipt && (
            <Button variant='outline' size='md' w='full' onClick={handlePrint}>
              Cetak Struk
            </Button>
          )}
        </>
      )}
      {!detailOrder.is_canceled && tabsIndex === 1 && (
        <Button variant='outline' size='md' w='full' onClick={onClickCancel}>
          Batalkan Retur
        </Button>
      )}
    </HStack>
  );
};

export default ButtonDetailHistory;
