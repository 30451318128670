import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  CheckboxGroup,
  Divider,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { OptionAccount } from 'types/super-account.types';

interface AccordionAccountProps {
  options: OptionAccount[];
  title: string;
}

const AccordionAccount: React.FC<AccordionAccountProps> = ({ options, title }: AccordionAccountProps) => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex='1' textAlign='left'>
            {title}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <VStack align='start'>
          {options.map((option: any, index) => (
            <VStack gap={3} key={index} w='full' alignItems='flex-start'>
              <Text fontWeight='bold'>{option.label}</Text>
              {option.access && (
                <SimpleGrid columns={[1, 4]} gap='8px'>
                  <CheckboxGroup>
                    {option.access.map((accessItem: any) => (
                      <Checkbox key={accessItem.key} isChecked={accessItem.checked} colorScheme='red' p={2}>
                        {accessItem.label}
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                </SimpleGrid>
              )}
              {option.child &&
                option.child.map((childOption: any) => (
                  <Box key={childOption.key} ml={4}>
                    <Text fontWeight='semibold'>{childOption.label}</Text>
                    <SimpleGrid columns={[1, 4]} gap='8px'>
                      <CheckboxGroup>
                        {childOption.access.map((accessItem: any) => (
                          <Checkbox
                            key={accessItem.key}
                            isChecked={accessItem.checked}
                            colorScheme='red'
                            p={2}
                          >
                            {accessItem.label}
                          </Checkbox>
                        ))}
                      </CheckboxGroup>
                    </SimpleGrid>
                  </Box>
                ))}
              {option.value && !option.access && !option.child && (
                <SimpleGrid columns={[1, 4]} gap='8px'>
                  <Checkbox isChecked={option.checked} colorScheme='red' p={2}>
                    {option.label}
                  </Checkbox>
                </SimpleGrid>
              )}
              <Divider />
            </VStack>
          ))}
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default AccordionAccount;
