export const sideMenu = [
  {
    id: 0,
    title: 'Daftar Pengguna',
    description: 'Daftar user yang dapat meangakses Akun Jubelio.',
    url: `/shared/user/list`,
  },
  {
    id: 1,
    title: 'Peran Pengguna',
    description: 'Daftar wewenang tugas yang dapat disematkan ke Pengguna.',
    url: `/shared/user/roles`,
  },{
    id: 2,
    title: 'Akun Support',
    description: 'Mengaktifkan mode support untuk agent jubelio dapat mengakses dashboard anda.',
    url: `/shared/user/support`,
  },
];

export const optionsSystem = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    checked: false,
    value: 9101,
  },
  {
    key: 'setting',
    label: 'Pengaturan',
    checked: false,
    type: 'sub',
    access: [
      { checked: false, key: 'company', label: 'Perusahaan', value: 9112 },
      { checked: false, key: 'integration', label: 'Integrasi', value: 9122 },
      { checked: false, key: 'user', label: 'Pengguna', value: 9132 },
      { checked: false, key: 'trx_number', label: 'Penomoran Transaksi', value: 9142 },
      { checked: false, key: 'developer', label: 'Developer', value: 9152 },
      { checked: false, key: 'delete_history', label: 'Riwayat Penghapusan', value: 9162 },
      { checked: false, key: 'jubelio_pos', label: 'Jubelio POS', value: 9172 },
      { checked: false, key: 'jubelio_store', label: 'Jubelio Store', value: 9182 },
    ],
  },
];

export const optionsKatalog = [
  {
    key: 'product',
    label: 'Produk',
    checked: false,
    child: [
      {
        key: 'product.master',
        label: 'Master',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 2111 },
          { checked: false, key: 'add', label: 'Tambah', value: 2112 },
          { checked: false, key: 'edit', label: 'Ubah', value: 2113 },
          { checked: false, key: 'delete', label: 'Hapus', value: 2114 },
        ],
      },
      {
        key: 'product.product_channel',
        label: 'Produk Channel',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 2121 },
          { checked: false, key: 'delete', label: 'Hapus', value: 2122 },
        ],
      },
      {
        key: 'product.mass_upload',
        label: 'Upload Massal',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 2131 },
          { checked: false, key: 'add', label: 'Tambah', value: 2132 },
          { checked: false, key: 'edit', label: 'Ubah', value: 2133 },
          { checked: false, key: 'delete', label: 'Hapus', value: 2134 },
        ],
      },
      {
        key: 'product.upload_result',
        label: 'Hasil Upload',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 2141 },
          { checked: false, key: 'add', label: 'Tambah', value: 2142 },
          { checked: false, key: 'edit', label: 'Ubah', value: 2143 },
          { checked: false, key: 'delete', label: 'Hapus', value: 2144 },
        ],
      },
      {
        key: 'product.download_result',
        label: 'Hasil Download',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 2151 },
          { checked: false, key: 'add', label: 'Tambah', value: 2152 },
          { checked: false, key: 'edit', label: 'Ubah', value: 2153 },
          { checked: false, key: 'delete', label: 'Hapus', value: 2154 },
        ],
      },
      {
        key: 'product.archive',
        label: 'Arsip',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 2161 },
          { checked: false, key: 'add', label: 'Tambah', value: 2162 },
          { checked: false, key: 'edit', label: 'Ubah', value: 2163 },
          { checked: false, key: 'delete', label: 'Hapus', value: 2164 },
        ],
      },
      {
        key: 'product.raise_product',
        label: 'Naikkan Produk',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 2171 },
          { checked: false, key: 'add', label: 'Tambah', value: 2172 },
          { checked: false, key: 'edit', label: 'Ubah', value: 2173 },
          { checked: false, key: 'delete', label: 'Hapus', value: 2174 },
        ],
      },
    ],
  },
  {
    key: 'price',
    label: 'Harga',
    checked: false,
    child: [
      {
        key: 'price.online_price',
        label: 'Harga Online',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 2211 },
          { checked: false, key: 'edit', label: 'Ubah', value: 2212 },
        ],
      },
      {
        key: 'price.offline_price',
        label: 'Harga Offline',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 2221 },
          { checked: false, key: 'edit', label: 'Ubah', value: 2222 },
        ],
      },
      {
        key: 'price.pricebook',
        label: 'Buku Harga',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 2231 },
          { checked: false, key: 'add', label: 'Tambah', value: 2232 },
          { checked: false, key: 'edit', label: 'Ubah', value: 2233 },
          { checked: false, key: 'delete', label: 'Hapus', value: 2234 },
        ],
      },
      {
        key: 'price.on_promo',
        label: 'Sedang Promo',
        checked: false,
        access: [{ checked: false, key: 'view', label: 'Lihat', value: 2241 }],
      },
      {
        key: 'price.failed_to_sync',
        label: 'Gagal Sinkron',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 2251 },
          { checked: false, key: 'edit', label: 'Ubah', value: 2252 },
        ],
      },
    ],
  },
  {
    key: 'promotion',
    label: 'Promosi',
    checked: false,
    child: [
      {
        key: 'promotion.online_promotion',
        label: 'Promosi Online',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 2311 },
          { checked: false, key: 'add', label: 'Tambah', value: 2312 },
          { checked: false, key: 'edit', label: 'Ubah', value: 2313 },
          { checked: false, key: 'delete', label: 'Hapus', value: 2314 },
        ],
      },
      {
        key: 'promotion.offline_promotion',
        label: 'Promosi Offline',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 2321 },
          { checked: false, key: 'add', label: 'Tambah', value: 2322 },
          { checked: false, key: 'edit', label: 'Ubah', value: 2323 },
          { checked: false, key: 'delete', label: 'Hapus', value: 2324 },
        ],
      },
    ],
  },
  {
    key: 'category',
    label: 'Kategori',
    checked: false,
    access: [
      { checked: false, key: 'view', label: 'Lihat', value: 2411 },
      { checked: false, key: 'add', label: 'Tambah', value: 2412 },
      { checked: false, key: 'edit', label: 'Ubah', value: 2413 },
      { checked: false, key: 'delete', label: 'Hapus', value: 2414 },
    ],
  },
  {
    key: 'report',
    label: 'Laporan',
    checked: false,
    type: 'sub',
    access: [
      { checked: false, key: 'product_price', label: 'Harga Produk', value: 2501 },
      { checked: false, key: 'online_product', label: 'Produk Online', value: 2502 },
    ],
  },
  {
    key: 'catalog_setting',
    label: 'Pengaturan',
    checked: false,
    value: 2611,
  },
];

export const optionsInventory = [
  {
    key: 'stock_position',
    label: 'Posisi Stok',
    checked: false,
    access: [
      { checked: false, key: 'view', label: 'Lihat', value: 3111 },
      { checked: false, key: 'edit', label: 'Ubah', value: 3112 },
    ],
  },
  {
    key: 'stock_monitor',
    label: 'Monitor Stok',
    checked: false,
    access: [
      { checked: false, key: 'view', label: 'Lihat', value: 3211 },
      { checked: false, key: 'edit', label: 'Ubah', value: 3212 },
    ],
  },
  {
    key: 'stock_transaction',
    label: 'Transaksi Stok',
    checked: false,
    child: [
      {
        key: 'stock_transaction.adjustment_stock',
        label: 'Penyesuaian Stok',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 3311 },
          { checked: false, key: 'add', label: 'Tambah', value: 3312 },
          { checked: false, key: 'edit', label: 'Ubah', value: 3313 },
          { checked: false, key: 'delete', label: 'Hapus', value: 3314 },
        ],
      },
      {
        key: 'stock_transaction.opname',
        label: 'Opname',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 3321 },
          { checked: false, key: 'add', label: 'Tambah', value: 3322 },
          { checked: false, key: 'edit', label: 'Ubah', value: 3323 },
          { checked: false, key: 'delete', label: 'Hapus', value: 3324 },
        ],
      },
      {
        key: 'stock_transaction.tf_internal',
        label: 'Internal Transfer',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 3331 },
          { checked: false, key: 'add', label: 'Tambah', value: 3332 },
          { checked: false, key: 'edit', label: 'Ubah', value: 3333 },
          { checked: false, key: 'delete', label: 'Hapus', value: 3334 },
        ],
      },
      {
        key: 'stock_transaction.reserve',
        label: 'Cadangan',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 3341 },
          { checked: false, key: 'add', label: 'Tambah', value: 3342 },
          { checked: false, key: 'edit', label: 'Ubah', value: 3343 },
          { checked: false, key: 'delete', label: 'Hapus', value: 3344 },
        ],
      },
      {
        key: 'stock_transaction.revaluation',
        label: 'Revaluasi',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 3351 },
          { checked: false, key: 'add', label: 'Tambah', value: 3352 },
          { checked: false, key: 'edit', label: 'Ubah', value: 3353 },
          { checked: false, key: 'delete', label: 'Hapus', value: 3354 },
        ],
      },
    ],
  },
  {
    key: 'hpp',
    label: 'HPP',
    checked: false,
    access: [{ checked: false, key: 'view', label: 'Lihat', value: 3411 }],
  },
  {
    key: 'stock_activity',
    label: 'Aktivitas Stok',
    checked: false,
    access: [{ checked: false, key: 'view', label: 'Lihat', value: 3511 }],
  },
  {
    key: 'report',
    label: 'Laporan',
    checked: false,
    type: 'sub',
    access: [
      { checked: false, key: 'item_inventory', label: 'Persediaan Barang', value: 3611 },
      { checked: false, key: 'item_chronology', label: 'Kronologi Barang', value: 3612 },
      { checked: false, key: 'item_movement', label: 'Pergerakan Barang', value: 3613 },
      { checked: false, key: 'adjustment', label: 'Penyesuaian', value: 3614 },
      { checked: false, key: 'stock_age', label: 'Umur Stok', value: 3615 },
      { checked: false, key: 'inventory_data_analysis', label: 'Analisa Data Persediaan', value: 3616 },
      { checked: false, key: 'serialbatch_data_analysis', label: 'Analisa Data Serial/Batch', value: 3617 },
      { checked: false, key: 'item_barcode', label: 'Barcode Barang', value: 3618 },
      { checked: false, key: 'item_barcode_by_price', label: 'Barcode Harga', value: 3625 },
      { checked: false, key: 'serial_number', label: 'Serial Number', value: 3619 },
      { checked: false, key: 'inventory_per_rack', label: 'Persediaan Per Rak', value: 3620 },
      { checked: false, key: 'batch_age', label: 'Umur Batch', value: 3621 },
      { checked: false, key: 'item_transfer', label: 'Transfer Internal', value: 3622 },
      { checked: false, key: 'inventory_bundle', label: 'Persediaan Barang Bundle', value: 3624 },
    ],
  },
  {
    key: 'inventory_setting',
    label: 'Pengaturan',
    checked: false,
    value: 3711,
  },
];

export const optionsSales = [
  {
    key: 'monitoring',
    label: 'Pantauan',
    checked: false,
    access: [{ checked: false, key: 'view', label: 'Lihat', value: 4101 }],
  },
  {
    key: 'order',
    label: 'Pesanan',
    checked: false,
    access: [
      { checked: false, key: 'view', label: 'Lihat', value: 4201 },
      { checked: false, key: 'add', label: 'Tambah', value: 4202 },
      { checked: false, key: 'edit', label: 'Ubah', value: 4203 },
      { checked: false, key: 'delete', label: 'Hapus', value: 4204 },
      { checked: false, key: 'process', label: 'Proses', value: 4205 },
    ],
  },
  {
    key: 'invoice_transaction',
    label: 'Transaksi Faktur',
    checked: false,
    child: [
      {
        key: 'invoice_transaction.invoice',
        label: 'Faktur',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 4301 },
          { checked: false, key: 'add', label: 'Tambah', value: 4302 },
          { checked: false, key: 'edit', label: 'Ubah', value: 4303 },
          { checked: false, key: 'delete', label: 'Hapus', value: 4304 },
          { checked: false, key: 'print', label: 'Cetak', value: 4305 },
        ],
      },
      {
        key: 'invoice_transaction.more_print_invoice',
        label: 'Cetak Faktur > 1 kali',
        checked: false,
        access: [{ checked: false, key: 'print', label: 'Cetak', value: 4311 }],
      },
      {
        key: 'invoice_transaction.termin-setting',
        label: 'Termin Pelanggan',
        checked: false,
        access: [{ checked: false, key: 'termin-setting', label: 'Ubah', value: 4312 }],
      },
    ],
  },
  {
    key: 'sales_retur',
    label: 'Retur Penjualan',
    checked: false,
    access: [
      { checked: false, key: 'view', label: 'Lihat', value: 4401 },
      { checked: false, key: 'add', label: 'Tambah', value: 4402 },
      { checked: false, key: 'edit', label: 'Ubah', value: 4403 },
      { checked: false, key: 'delete', label: 'Hapus', value: 4404 },
    ],
  },
  {
    key: 'contact',
    label: 'Kontak',
    checked: false,
    access: [
      { checked: false, key: 'view', label: 'Lihat', value: 4501 },
      { checked: false, key: 'add', label: 'Tambah', value: 4502 },
      { checked: false, key: 'edit', label: 'Ubah', value: 4503 },
      { checked: false, key: 'delete', label: 'Hapus', value: 4504 },
      { checked: false, key: 'export', label: 'Export', value: 4505 },
    ],
  },
  {
    key: 'tax_invoice_no',
    label: 'No Faktur Pajak',
    checked: false,
    access: [
      { checked: false, key: 'view', label: 'Lihat', value: 4901 },
      { checked: false, key: 'add', label: 'Tambah', value: 4902 },
      { checked: false, key: 'edit', label: 'Ubah', value: 4903 },
    ],
  },
  {
    key: 'tax_invoices',
    label: 'Faktur Pajak',
    checked: false,
    access: [
      { checked: false, key: 'view', label: 'Lihat', value: 4911 },
      { checked: false, key: 'add', label: 'Tambah', value: 4912 },
      { checked: false, key: 'edit', label: 'Ubah', value: 4913 },
      { checked: false, key: 'export', label: 'Export', value: 4914 },
    ],
  },
  {
    key: 'report',
    label: 'Laporan',
    checked: false,
    type: 'sub',
    access: [
      { checked: false, key: 'sales', label: 'Penjualan', value: 4601 },
      { checked: false, key: 'product_sales', label: 'Penjualan Produk', value: 4602 },
      { checked: false, key: 'sales_data_analysis', label: 'Analisa Data Penjualan', value: 4603 },
      { checked: false, key: 'sales_retur', label: 'Retur Penjualan', value: 4604 },
      { checked: false, key: 'serialnumber_sold', label: 'Serial Number Terjual', value: 4605 },
      { checked: false, key: 'sales_pos', label: 'Penjualan POS', value: 4606 },
      { checked: false, key: 'income_detail', label: 'Rincian Pendapatan', value: 4607 },
      { checked: false, key: 'customer_list', label: 'Daftar Pelanggan', value: 4608 },
      { checked: false, key: 'print_receipt', label: 'Cetak Struk', value: 4609 },
      { checked: false, key: 'bundle_sales', label: 'Penjualan Bundle', value: 4610 },
      { checked: false, key: 'sale_escrow_amount', label: 'Rincian Nilai Escrow', value: 4612 },
    ],
  },
  {
    key: 'sales_setting',
    label: 'Pengaturan',
    checked: false,
    value: 4701,
  },
  {
    key: 'pos-register',
    label: 'Mesin Kasir POS',
    checked: false,
    value: 4801,
  },
];

export const optionsPurchase = [
  {
    key: 'order',
    label: 'Pesanan',
    checked: false,
    access: [
      { checked: false, key: 'view', label: 'Lihat', value: 5101 },
      { checked: false, key: 'add', label: 'Tambah', value: 5102 },
      { checked: false, key: 'edit', label: 'Ubah', value: 5103 },
      { checked: false, key: 'delete', label: 'Hapus', value: 5104 },
    ],
  },
  {
    key: 'bill',
    label: 'Tagihan',
    checked: false,
    access: [
      { checked: false, key: 'view', label: 'Lihat', value: 5201 },
      { checked: false, key: 'add', label: 'Tambah', value: 5202 },
      { checked: false, key: 'edit', label: 'Ubah', value: 5203 },
      { checked: false, key: 'delete', label: 'Hapus', value: 5204 },
    ],
  },
  {
    key: 'purchase_retur',
    label: 'Retur Pembelian',
    checked: false,
    access: [
      { checked: false, key: 'view', label: 'Lihat', value: 5301 },
      { checked: false, key: 'add', label: 'Tambah', value: 5302 },
      { checked: false, key: 'edit', label: 'Ubah', value: 5303 },
      { checked: false, key: 'delete', label: 'Hapus', value: 5304 },
    ],
  },
  {
    key: 'contact',
    label: 'Kontak',
    checked: false,
    access: [
      { checked: false, key: 'view', label: 'Lihat', value: 5401 },
      { checked: false, key: 'add', label: 'Tambah', value: 5402 },
      { checked: false, key: 'edit', label: 'Ubah', value: 5403 },
      { checked: false, key: 'delete', label: 'Hapus', value: 5404 },
    ],
  },
  {
    key: 'report',
    label: 'Laporan',
    checked: false,
    type: 'sub',
    access: [
      { checked: false, key: 'purchase', label: 'Pembelian', value: 5501 },
      { checked: false, key: 'product_purchase', label: 'Pembelian Produk', value: 5502 },
      { checked: false, key: 'purchase_order', label: 'Pesanan Pembelian', value: 5503 },
      { checked: false, key: 'purchase_retur', label: 'Retur Pembelian', value: 5504 },
    ],
  },
];

export const optionsWarehouse = [
  {
    key: 'order_process',
    label: 'Proses Pesanan',
    checked: false,
    child: [
      {
        key: 'order_process.monitor',
        label: 'Pantauan',
        checked: false,
        access: [{ checked: false, key: 'view', label: 'Lihat', value: 6111 }],
      },
      {
        key: 'order_process.picking',
        label: 'Picking',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 6121 },
          { checked: false, key: 'add', label: 'Tambah', value: 6122 },
          { checked: false, key: 'edit', label: 'Ubah', value: 6123 },
          { checked: false, key: 'delete', label: 'Hapus', value: 6124 },
          { checked: false, key: 'print', label: 'Cetak', value: 6125 },
        ],
      },
      {
        key: 'order_process.packing',
        label: 'Packing',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 6131 },
          { checked: false, key: 'add', label: 'Tambah', value: 6132 },
          { checked: false, key: 'edit', label: 'Ubah', value: 6133 },
          { checked: false, key: 'delete', label: 'Hapus', value: 6134 },
          { checked: false, key: 'print', label: 'Cetak', value: 6135 },
        ],
      },
      {
        key: 'order_process.shipping',
        label: 'Shipping',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 6141 },
          { checked: false, key: 'add', label: 'Tambah', value: 6142 },
          { checked: false, key: 'edit', label: 'Ubah', value: 6143 },
          { checked: false, key: 'delete', label: 'Hapus', value: 6144 },
          { checked: false, key: 'print', label: 'Cetak', value: 6145 },
        ],
      },
      {
        key: 'order_process.shipped',
        label: 'Sudah dikirim',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 6151 },
          { checked: false, key: 'edit', label: 'Ubah', value: 6152 },
          { checked: false, key: 'delete', label: 'Hapus', value: 6153 },
        ],
      },
      {
        key: 'order_process.done',
        label: 'Selesai',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 6161 },
          { checked: false, key: 'edit', label: 'Ubah', value: 6162 },
          { checked: false, key: 'print', label: 'Cetak', value: 6164 },
        ],
      },
    ],
  },
  {
    key: 'more_print_label',
    label: 'Cetak Label > 1 kali',
    checked: false,
    access: [{ checked: false, key: 'print', label: 'Cetak', value: 6171 }],
  },
  {
    key: 'item_in',
    label: 'Barang Masuk',
    checked: false,
    child: [
      {
        key: 'item_in.item_receive',
        label: 'Penerimaan Barang',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 6211 },
          { checked: false, key: 'add', label: 'Tambah', value: 6212 },
          { checked: false, key: 'edit', label: 'Ubah', value: 6213 },
          { checked: false, key: 'delete', label: 'Hapus', value: 6214 },
          { checked: false, key: 'print', label: 'Cetak', value: 6215 },
        ],
      },
      {
        key: 'item_in.item_placement',
        label: 'Penempatan Barang',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 6221 },
          { checked: false, key: 'add', label: 'Tambah', value: 6222 },
          { checked: false, key: 'edit', label: 'Ubah', value: 6223 },
          { checked: false, key: 'delete', label: 'Hapus', value: 6224 },
          { checked: false, key: 'print', label: 'Cetak', value: 6225 },
        ],
      },
    ],
  },
  {
    key: 'item_out',
    label: 'Barang Keluar',
    checked: false,
    child: [
      {
        key: 'item_out.purchase_return',
        label: 'Retur Pembelian',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 6311 },
          { checked: false, key: 'add', label: 'Tambah', value: 6312 },
          { checked: false, key: 'edit', label: 'Ubah', value: 6313 },
          { checked: false, key: 'delete', label: 'Hapus', value: 6314 },
        ],
      },
      {
        key: 'item_out.transfer_out',
        label: 'Transfer Keluar',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 6321 },
          { checked: false, key: 'add', label: 'Tambah', value: 6322 },
          { checked: false, key: 'edit', label: 'Ubah', value: 6323 },
          { checked: false, key: 'delete', label: 'Hapus', value: 6324 },
          { checked: false, key: 'print', label: 'Cetak', value: 6325 },
        ],
      },
      {
        key: 'item_out.transfer_receive',
        label: 'Terima Transfer',
        checked: false,
        access: [{ checked: false, key: 'add', label: 'Tambah', value: 6331 }],
      },
    ],
  },
  {
    key: 'report',
    label: 'Laporan',
    checked: false,
    type: 'sub',
    access: [
      { checked: false, key: 'transfer', label: 'Transfer', value: 6401 },
      { checked: false, key: 'picklist', label: 'Daftar Picklist', value: 6402 },
      { checked: false, key: 'shipment_list', label: 'Daftar Pengiriman', value: 6403 },
      { checked: false, key: 'order_process_perform', label: 'Performa Proses Pesanan', value: 6404 },
      { checked: false, key: 'placement_process_perform', label: 'Performa Proses Penempatan', value: 6405 },
      { checked: false, key: 'item_placement', label: 'Penempatan Barang', value: 6406 },
    ],
  },
  {
    key: 'warehouse_setting',
    label: 'Pengaturan',
    checked: false,
    value: 6511,
  },
];

export const optionsFinance = [
  {
    key: 'account',
    label: 'Akun',
    checked: false,
    access: [
      { checked: false, key: 'view', label: 'Lihat', value: 7101 },
      { checked: false, key: 'add', label: 'Tambah', value: 7102 },
      { checked: false, key: 'edit', label: 'Ubah', value: 7103 },
      { checked: false, key: 'delete', label: 'Hapus', value: 7104 },
    ],
  },
  {
    key: 'debt',
    label: 'Hutang',
    checked: false,
    access: [
      { checked: false, key: 'view', label: 'Lihat', value: 7201 },
      { checked: false, key: 'add', label: 'Tambah', value: 7202 },
      { checked: false, key: 'edit', label: 'Ubah', value: 7203 },
      { checked: false, key: 'delete', label: 'Hapus', value: 7204 },
    ],
  },
  {
    key: 'loan',
    label: 'Piutang',
    checked: false,
    access: [
      { checked: false, key: 'view', label: 'Lihat', value: 7301 },
      { checked: false, key: 'add', label: 'Tambah', value: 7302 },
      { checked: false, key: 'edit', label: 'Ubah', value: 7303 },
      { checked: false, key: 'delete', label: 'Hapus', value: 7304 },
    ],
  },
  {
    key: 'cashbank',
    label: 'Kas dan Bank',
    checked: false,
    child: [
      {
        key: 'cashbank.kas',
        label: 'Kas',
        checked: false,
        access: [{ checked: false, key: 'view', label: 'Lihat', value: 7411 }],
      },
      {
        key: 'cashbank.bank',
        label: 'Bank',
        checked: false,
        access: [{ checked: false, key: 'view', label: 'Lihat', value: 7421 }],
      },
      {
        key: 'cashbank.rekonsiliasi_bank',
        label: 'Rekonsiliasi Bank',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 7431 },
          { checked: false, key: 'add', label: 'Tambah', value: 7432 },
          // { checked: false, key: 'edit', label: 'Ubah', value: 7433 },
          { checked: false, key: 'delete', label: 'Hapus', value: 7434 },
        ],
      },
      {
        key: 'cashbank.transaksi_kas_bank',
        label: 'Transaksi Kas Bank',
        checked: false,
        access: [
          { checked: false, key: 'view', label: 'Lihat', value: 7401 },
          { checked: false, key: 'add', label: 'Tambah', value: 7402 },
          { checked: false, key: 'edit', label: 'Ubah', value: 7403 },
          { checked: false, key: 'delete', label: 'Hapus', value: 7404 },
        ],
      },
    ],
  },
  {
    key: 'journal',
    label: 'Jurnal',
    checked: false,
    access: [
      { checked: false, key: 'view', label: 'Lihat', value: 7501 },
      { checked: false, key: 'add', label: 'Tambah', value: 7502 },
      { checked: false, key: 'edit', label: 'Ubah', value: 7503 },
      { checked: false, key: 'delete', label: 'Hapus', value: 7504 },
    ],
  },
  {
    key: 'report',
    label: 'Laporan',
    checked: false,
    type: 'sub',
    access: [
      { checked: false, key: 'neraca', label: 'Neraca', value: 7601 },
      { checked: false, key: 'neraca_saldo', label: 'Neraca Saldo', value: 7602 },
      { checked: false, key: 'buku_besar', label: 'Buku Besar', value: 7603 },
      { checked: false, key: 'laba_rugi', label: 'Laba Rugi', value: 7604 },
      { checked: false, key: 'performa_per_tag', label: 'Performa Per Tag', value: 7615 },
      { checked: false, key: 'cashbank_mutation', label: 'Mutasi Kas dan Bank', value: 7605 },
      { checked: false, key: 'journal_list', label: 'Daftar Jurnal', value: 7606 },
      { checked: false, key: 'hpp_chronology', label: 'Kronologi HPP', value: 7607 },
      { checked: false, key: 'supplier_loan', label: 'Hutang Pemasok', value: 7608 },
      { checked: false, key: 'loan_age', label: 'Umur Hutang', value: 7609 },
      { checked: false, key: 'customer_debt', label: 'Piutang', value: 7610 },
      { checked: false, key: 'debt_age', label: 'Umur Piutang', value: 7611 },
      { checked: false, key: 'debt_age_detail', label: 'Detail Umur Piutang', value: 7612 },
      { checked: false, key: 'invoice_payment', label: 'Pembayaran Faktur', value: 7613 },
      { checked: false, key: 'bill_payment', label: 'Pembayaran Tagihan', value: 7614 },
    ],
  },
  {
    key: 'finance_setting',
    label: 'Pengaturan',
    checked: false,
    value: 7701,
  },
];

export const optionsPOS = [
  {
    key: 'sales_page',
    label: 'Sales Page',
    checked: false,
    value: 815,
  },
  {
    key: 'price_checker',
    label: 'Price Checker',
    checked: false,
    value: 817,
  },
  {
    key: 'retur_otorization',
    label: 'Otorisasi Retur',
    checked: false,
    value: 819,
  },
  {
    key: 'cancel_otorization',
    label: 'Otorisasi Pembatalan',
    checked: false,
    value: 820,
  },
  {
    key: 'dp_otorization',
    label: 'Otorisasi DP',
    checked: false,
    value: 821,
  },
  {
    key: 'reprint_otorization',
    label: 'Otorisasi Cetak Ulang Struk',
    checked: false,
    value: 822,
  },
  {
    key: 'send_email_otorization',
    label: 'Otorisasi Kirim Ulang Struk ke Email',
    checked: false,
    value: 823,
  },
  {
    key: 'backoffice_item',
    label: 'Backoffice: Barang',
    checked: false,
    value: 800,
  },
  {
    key: 'backoffice_inventory',
    label: 'Backoffice: Persediaan',
    checked: false,
    value: 801,
  },
  {
    key: 'backoffice_pricebook',
    label: 'Backoffice: Buku Harga',
    checked: false,
    value: 802,
  },
  {
    key: 'backoffice_outlet',
    label: 'Backoffice: Outlet',
    checked: false,
    value: 803,
  },
  {
    key: 'backoffice_transfer_receive',
    label: 'Backoffice: Penerimaan Transfer',
    checked: false,
    value: 804,
  },
  {
    key: 'backoffice_sales',
    label: 'Backoffice: Penjualan',
    checked: false,
    value: 805,
  },
  {
    key: 'backoffice_return',
    label: 'Backoffice: Retur',
    checked: false,
    value: 806,
  },
  {
    key: 'backoffice_discount',
    label: 'Backoffice: Harga Coret',
    checked: false,
    value: 807,
  },
  {
    key: 'backoffice_promotion',
    label: 'Backoffice: Promosi',
    checked: false,
    value: 808,
  },
  {
    key: 'backoffice_report',
    label: 'Backoffice: Laporan',
    checked: false,
    value: 809,
  },
  {
    key: 'backoffice_outlet_resume',
    label: 'Backoffice: Ringkasan Outlet',
    checked: false,
    value: 810,
  },
  {
    key: 'backoffice_cashier_resume',
    label: 'Backoffice: Ringkasan Mesin Kasir',
    checked: false,
    value: 811,
  },
  {
    key: 'backoffice_setting',
    label: 'Backoffice: Pengaturan - Umum',
    checked: false,
    value: 812,
  },
  {
    key: 'backoffice_payment',
    label: 'Backoffice: Pembayaran',
    checked: false,
    value: 813,
  },
  {
    key: 'backoffice_struct',
    label: 'Backoffice: Struk',
    checked: false,
    value: 814,
  },
  {
    key: 'backoffice_dashboard',
    label: 'Backoffice: Dashboard',
    checked: false,
    value: 816,
  },
  {
    key: 'backoffice_email',
    label: 'Backoffice: Email',
    checked: false,
    value: 818,
  },
  {
    key: 'cashier_order_mp',
    label: 'Cashier: Pesanan Marketplace',
    checked: false,
    value: 300,
  },
  {
    key: 'cashier_print_label',
    label: 'Cashier: Cetak Label Pengiriman',
    checked: false,
    value: 335,
  },
];

export const ADMIN_MENU = {
  setting_acls: [
    { id: 9101 },
    { id: 9112 },
    { id: 9122 },
    { id: 9132 },
    { id: 9142 },
    { id: 9152 },
    { id: 9162 },
    { id: 9172 },
    { id: 9182 },
  ],
  catalog_acls: [
    { id: 2111 },
    { id: 2112 },
    { id: 2113 },
    { id: 2114 },
    { id: 2121 },
    { id: 2122 },
    { id: 2131 },
    { id: 2132 },
    { id: 2133 },
    { id: 2134 },
    { id: 2141 },
    { id: 2142 },
    { id: 2143 },
    { id: 2144 },
    { id: 2151 },
    { id: 2152 },
    { id: 2153 },
    { id: 2154 },
    { id: 2161 },
    { id: 2162 },
    { id: 2163 },
    { id: 2164 },
    { id: 2171 },
    { id: 2172 },
    { id: 2173 },
    { id: 2174 },
    { id: 2211 },
    { id: 2212 },
    { id: 2221 },
    { id: 2222 },
    { id: 2231 },
    { id: 2232 },
    { id: 2233 },
    { id: 2234 },
    { id: 2241 },
    { id: 2251 },
    { id: 2252 },
    { id: 2311 },
    { id: 2312 },
    { id: 2313 },
    { id: 2314 },
    { id: 2321 },
    { id: 2322 },
    { id: 2323 },
    { id: 2324 },
    { id: 2411 },
    { id: 2412 },
    { id: 2413 },
    { id: 2414 },
    { id: 2501 },
    { id: 2502 },
    { id: 2611 },
  ],
  inventory_acls: [
    { id: 3111 },
    { id: 3112 },
    { id: 3211 },
    { id: 3212 },
    { id: 3311 },
    { id: 3312 },
    { id: 3313 },
    { id: 3314 },
    { id: 3321 },
    { id: 3322 },
    { id: 3323 },
    { id: 3324 },
    { id: 3331 },
    { id: 3332 },
    { id: 3333 },
    { id: 3334 },
    { id: 3341 },
    { id: 3342 },
    { id: 3343 },
    { id: 3344 },
    { id: 3351 },
    { id: 3352 },
    { id: 3353 },
    { id: 3354 },
    { id: 3411 },
    { id: 3611 },
    { id: 3612 },
    { id: 3613 },
    { id: 3614 },
    { id: 3615 },
    { id: 3616 },
    { id: 3617 },
    { id: 3618 },
    { id: 3619 },
    { id: 3620 },
    { id: 3621 },
    { id: 3622 },
    { id: 3625 },
    { id: 3711 },
  ],
  sales_acls: [
    { id: 4101 },
    { id: 4201 },
    { id: 4202 },
    { id: 4203 },
    { id: 4204 },
    { id: 4205 },
    { id: 4301 },
    { id: 4302 },
    { id: 4303 },
    { id: 4304 },
    { id: 4305 },
    { id: 4312 },
    { id: 4311 },
    { id: 4401 },
    { id: 4402 },
    { id: 4403 },
    { id: 4404 },
    { id: 4501 },
    { id: 4502 },
    { id: 4503 },
    { id: 4504 },
    { id: 4505 },
    { id: 4601 },
    { id: 4602 },
    { id: 4603 },
    { id: 4604 },
    { id: 4605 },
    { id: 4606 },
    { id: 4607 },
    { id: 4608 },
    { id: 4609 },
    { id: 4701 },
    { id: 4801 },
  ],
  purchase_acls: [
    { id: 5101 },
    { id: 5102 },
    { id: 5103 },
    { id: 5104 },
    { id: 5201 },
    { id: 5202 },
    { id: 5203 },
    { id: 5204 },
    { id: 5301 },
    { id: 5302 },
    { id: 5303 },
    { id: 5304 },
    { id: 5401 },
    { id: 5402 },
    { id: 5403 },
    { id: 5404 },
    { id: 5501 },
    { id: 5502 },
    { id: 5503 },
    { id: 5504 },
  ],
  warehouse_acls: [
    { id: 6111 },
    { id: 6121 },
    { id: 6122 },
    { id: 6123 },
    { id: 6124 },
    { id: 6125 },
    { id: 6131 },
    { id: 6132 },
    { id: 6133 },
    { id: 6134 },
    { id: 6135 },
    { id: 6141 },
    { id: 6142 },
    { id: 6143 },
    { id: 6144 },
    { id: 6145 },
    { id: 6151 },
    { id: 6152 },
    { id: 6153 },
    { id: 6161 },
    { id: 6162 },
    { id: 6163 },
    { id: 6164 },
    { id: 6171 },
    { id: 6211 },
    { id: 6212 },
    { id: 6213 },
    { id: 6214 },
    { id: 6215 },
    { id: 6221 },
    { id: 6222 },
    { id: 6223 },
    { id: 6224 },
    { id: 6225 },
    { id: 6311 },
    { id: 6312 },
    { id: 6313 },
    { id: 6314 },
    { id: 6321 },
    { id: 6322 },
    { id: 6323 },
    { id: 6324 },
    { id: 6325 },
    { id: 6331 },
    { id: 6401 },
    { id: 6402 },
    { id: 6403 },
    { id: 6404 },
    { id: 6405 },
    { id: 6406 },
    { id: 6511 },
  ],
  finance_acls: [
    { id: 7101 },
    { id: 7102 },
    { id: 7103 },
    { id: 7104 },
    { id: 7201 },
    { id: 7202 },
    { id: 7203 },
    { id: 7204 },
    { id: 7301 },
    { id: 7302 },
    { id: 7303 },
    { id: 7304 },
    { id: 7411 },
    { id: 7421 },
    { id: 7431 },
    { id: 7432 },
    { id: 7434 },
    { id: 7401 },
    { id: 7402 },
    { id: 7403 },
    { id: 7404 },
    { id: 7501 },
    { id: 7502 },
    { id: 7503 },
    { id: 7504 },
    { id: 7601 },
    { id: 7602 },
    { id: 7603 },
    { id: 7604 },
    { id: 7605 },
    { id: 7606 },
    { id: 7607 },
    { id: 7608 },
    { id: 7609 },
    { id: 7610 },
    { id: 7611 },
    { id: 7612 },
    { id: 7613 },
    { id: 7701 },
  ],
  pos_acls: [],
};

export const SUPERVISOR_MENU = {
  setting_acls: [],
  catalog_acls: [
    { id: 2111 },
    { id: 2112 },
    { id: 2113 },
    { id: 2114 },
    { id: 2121 },
    { id: 2122 },
    { id: 2131 },
    { id: 2132 },
    { id: 2133 },
    { id: 2134 },
    { id: 2141 },
    { id: 2142 },
    { id: 2143 },
    { id: 2144 },
    { id: 2151 },
    { id: 2152 },
    { id: 2153 },
    { id: 2154 },
    { id: 2161 },
    { id: 2162 },
    { id: 2163 },
    { id: 2164 },
    { id: 2171 },
    { id: 2172 },
    { id: 2173 },
    { id: 2174 },
    { id: 2211 },
    { id: 2212 },
    { id: 2221 },
    { id: 2222 },
    { id: 2231 },
    { id: 2232 },
    { id: 2233 },
    { id: 2234 },
    { id: 2241 },
    { id: 2251 },
    { id: 2252 },
    { id: 2311 },
    { id: 2312 },
    { id: 2313 },
    { id: 2314 },
    { id: 2321 },
    { id: 2322 },
    { id: 2323 },
    { id: 2324 },
    { id: 2411 },
    { id: 2412 },
    { id: 2413 },
    { id: 2414 },
    { id: 2501 },
    { id: 2502 },
  ],
  inventory_acls: [
    { id: 3111 },
    { id: 3112 },
    { id: 3211 },
    { id: 3212 },
    { id: 3311 },
    { id: 3312 },
    { id: 3313 },
    { id: 3314 },
    { id: 3321 },
    { id: 3322 },
    { id: 3323 },
    { id: 3324 },
    { id: 3331 },
    { id: 3332 },
    { id: 3333 },
    { id: 3334 },
    { id: 3341 },
    { id: 3342 },
    { id: 3343 },
    { id: 3344 },
    { id: 3351 },
    { id: 3352 },
    { id: 3353 },
    { id: 3354 },
    { id: 3411 },
    { id: 3611 },
    { id: 3612 },
    { id: 3613 },
    { id: 3614 },
    { id: 3615 },
    { id: 3616 },
    { id: 3617 },
    { id: 3618 },
    { id: 3619 },
    { id: 3620 },
    { id: 3621 },
    { id: 3622 },
    { id: 3625 },
  ],
  sales_acls: [
    { id: 4101 },
    { id: 4201 },
    { id: 4202 },
    { id: 4203 },
    { id: 4204 },
    { id: 4205 },
    { id: 4301 },
    { id: 4302 },
    { id: 4303 },
    { id: 4304 },
    { id: 4305 },
    { id: 4312 },
    { id: 4401 },
    { id: 4402 },
    { id: 4403 },
    { id: 4404 },
    { id: 4501 },
    { id: 4502 },
    { id: 4503 },
    { id: 4504 },
    { id: 4505 },
    { id: 4601 },
    { id: 4602 },
    { id: 4603 },
    { id: 4604 },
    { id: 4605 },
    { id: 4607 },
    { id: 4608 },
  ],
  purchase_acls: [
    { id: 5101 },
    { id: 5102 },
    { id: 5103 },
    { id: 5104 },
    { id: 5201 },
    { id: 5202 },
    { id: 5203 },
    { id: 5204 },
    { id: 5301 },
    { id: 5302 },
    { id: 5303 },
    { id: 5304 },
    { id: 5401 },
    { id: 5402 },
    { id: 5403 },
    { id: 5404 },
    { id: 5501 },
    { id: 5502 },
    { id: 5503 },
    { id: 5504 },
  ],
  warehouse_acls: [
    { id: 6111 },
    { id: 6121 },
    { id: 6122 },
    { id: 6123 },
    { id: 6124 },
    { id: 6125 },
    { id: 6131 },
    { id: 6132 },
    { id: 6133 },
    { id: 6134 },
    { id: 6135 },
    { id: 6141 },
    { id: 6142 },
    { id: 6143 },
    { id: 6144 },
    { id: 6145 },
    { id: 6151 },
    { id: 6152 },
    { id: 6153 },
    { id: 6161 },
    { id: 6162 },
    { id: 6163 },
    { id: 6164 },
    { id: 6211 },
    { id: 6212 },
    { id: 6213 },
    { id: 6214 },
    { id: 6215 },
    { id: 6221 },
    { id: 6222 },
    { id: 6223 },
    { id: 6224 },
    { id: 6225 },
    { id: 6311 },
    { id: 6312 },
    { id: 6313 },
    { id: 6314 },
    { id: 6321 },
    { id: 6322 },
    { id: 6323 },
    { id: 6324 },
    { id: 6325 },
    { id: 6331 },
    { id: 6401 },
    { id: 6402 },
    { id: 6403 },
    { id: 6404 },
    { id: 6405 },
    { id: 6406 },
  ],
  finance_acls: [
    { id: 7101 },
    { id: 7102 },
    { id: 7103 },
    { id: 7104 },
    { id: 7201 },
    { id: 7202 },
    { id: 7203 },
    { id: 7204 },
    { id: 7301 },
    { id: 7302 },
    { id: 7303 },
    { id: 7304 },
    { id: 7411 },
    { id: 7421 },
    { id: 7431 },
    { id: 7432 },
    { id: 7434 },
    { id: 7401 },
    { id: 7402 },
    { id: 7403 },
    { id: 7404 },
    { id: 7501 },
    { id: 7502 },
    { id: 7503 },
    { id: 7504 },
    { id: 7601 },
    { id: 7602 },
    { id: 7603 },
    { id: 7604 },
    { id: 7605 },
    { id: 7606 },
    { id: 7607 },
    { id: 7608 },
    { id: 7609 },
    { id: 7610 },
    { id: 7611 },
    { id: 7612 },
    { id: 7613 },
  ],
  pos_acls: [],
};

export const BOOKKEEPER_MENU = {
  setting_acls: [],
  catalog_acls: [],
  inventory_acls: [],
  sales_acls: [],
  purchase_acls: [],
  warehouse_acls: [],
  finance_acls: [
    { id: 7101 },
    { id: 7102 },
    { id: 7103 },
    { id: 7104 },
    { id: 7201 },
    { id: 7202 },
    { id: 7203 },
    { id: 7204 },
    { id: 7301 },
    { id: 7302 },
    { id: 7303 },
    { id: 7304 },
    { id: 7411 },
    { id: 7421 },
    { id: 7431 },
    { id: 7432 },
    { id: 7434 },
    { id: 7401 },
    { id: 7402 },
    { id: 7403 },
    { id: 7404 },
    { id: 7501 },
    { id: 7502 },
    { id: 7503 },
    { id: 7504 },
    { id: 7601 },
    { id: 7602 },
    { id: 7603 },
    { id: 7604 },
    { id: 7605 },
    { id: 7606 },
    { id: 7607 },
    { id: 7608 },
    { id: 7609 },
    { id: 7610 },
    { id: 7611 },
    { id: 7612 },
    { id: 7613 },
  ],
  pos_acls: [],
};

export const WAREHOUSE_MENU = {
  setting_acls: [],
  catalog_acls: [],
  inventory_acls: [
    { id: 3111 },
    { id: 3211 },
    { id: 3311 },
    { id: 3312 },
    { id: 3313 },
    { id: 3314 },
    { id: 3321 },
    { id: 3322 },
    { id: 3323 },
    { id: 3324 },
    { id: 3331 },
    { id: 3332 },
    { id: 3333 },
    { id: 3334 },
    { id: 3611 },
    { id: 3612 },
    { id: 3613 },
    { id: 3614 },
    { id: 3615 },
    { id: 3616 },
    { id: 3617 },
    { id: 3618 },
    { id: 3619 },
    { id: 3620 },
    { id: 3621 },
    { id: 3622 },
    { id: 3625 },
  ],
  sales_acls: [],
  purchase_acls: [],
  warehouse_acls: [
    { id: 6111 },
    { id: 6121 },
    { id: 6122 },
    { id: 6123 },
    { id: 6124 },
    { id: 6125 },
    { id: 6131 },
    { id: 6132 },
    { id: 6133 },
    { id: 6134 },
    { id: 6135 },
    { id: 6141 },
    { id: 6142 },
    { id: 6143 },
    { id: 6144 },
    { id: 6145 },
    { id: 6151 },
    { id: 6152 },
    { id: 6153 },
    { id: 6161 },
    { id: 6162 },
    { id: 6164 },
    { id: 6211 },
    { id: 6212 },
    { id: 6213 },
    { id: 6214 },
    { id: 6215 },
    { id: 6221 },
    { id: 6222 },
    { id: 6223 },
    { id: 6224 },
    { id: 6225 },
    { id: 6311 },
    { id: 6312 },
    { id: 6313 },
    { id: 6314 },
    { id: 6321 },
    { id: 6322 },
    { id: 6323 },
    { id: 6324 },
    { id: 6325 },
    { id: 6331 },
    { id: 6401 },
    { id: 6402 },
    { id: 6403 },
    { id: 6404 },
    { id: 6405 },
    { id: 6406 },
  ],
  finance_acls: [],
  pos_acls: [],
};

export const CS_MENU = {
  setting_acls: [],
  catalog_acls: [
    { id: 2111 },
    { id: 2121 },
    { id: 2131 },
    { id: 2141 },
    { id: 2151 },
    { id: 2161 },
    { id: 2171 },
    { id: 2211 },
    { id: 2221 },
    { id: 2231 },
    { id: 2241 },
    { id: 2251 },
    { id: 2311 },
    { id: 2321 },
  ],
  inventory_acls: [{ id: 3111 }],
  sales_acls: [{ id: 4101 }, { id: 4201 }, { id: 4301 }, { id: 4401 }],
  purchase_acls: [],
  warehouse_acls: [],
  finance_acls: [],
  pos_acls: [],
};

export const MERCHANDISER_MENU = {
  setting_acls: [],
  catalog_acls: [
    { id: 2111 },
    { id: 2112 },
    { id: 2113 },
    { id: 2114 },
    { id: 2121 },
    { id: 2122 },
    { id: 2131 },
    { id: 2132 },
    { id: 2133 },
    { id: 2134 },
    { id: 2141 },
    { id: 2142 },
    { id: 2143 },
    { id: 2144 },
    { id: 2151 },
    { id: 2152 },
    { id: 2153 },
    { id: 2154 },
    { id: 2161 },
    { id: 2162 },
    { id: 2163 },
    { id: 2164 },
    { id: 2171 },
    { id: 2172 },
    { id: 2173 },
    { id: 2174 },
    { id: 2211 },
    { id: 2212 },
    { id: 2221 },
    { id: 2222 },
    { id: 2231 },
    { id: 2232 },
    { id: 2233 },
    { id: 2234 },
    { id: 2241 },
    { id: 2251 },
    { id: 2252 },
    { id: 2311 },
    { id: 2312 },
    { id: 2313 },
    { id: 2314 },
    { id: 2321 },
    { id: 2322 },
    { id: 2323 },
    { id: 2324 },
    { id: 2411 },
    { id: 2412 },
    { id: 2413 },
    { id: 2414 },
    { id: 2501 },
    { id: 2502 },
  ],
  inventory_acls: [
    { id: 3111 },
    { id: 3112 },
    { id: 3211 },
    { id: 3212 },
    { id: 3611 },
    { id: 3612 },
    { id: 3613 },
    { id: 3614 },
    { id: 3615 },
    { id: 3616 },
    { id: 3617 },
    { id: 3618 },
    { id: 3619 },
    { id: 3620 },
    { id: 3621 },
    { id: 3622 },
    { id: 3625 },
  ],
  sales_acls: [],
  purchase_acls: [],
  warehouse_acls: [],
  finance_acls: [],
  pos_acls: [],
};

export const PURCHASING_MENU = {
  setting_acls: [],
  catalog_acls: [],
  inventory_acls: [],
  sales_acls: [],
  purchase_acls: [
    { id: 5101 },
    { id: 5102 },
    { id: 5103 },
    { id: 5104 },
    { id: 5201 },
    { id: 5202 },
    { id: 5203 },
    { id: 5204 },
    { id: 5301 },
    { id: 5302 },
    { id: 5303 },
    { id: 5304 },
    { id: 5401 },
    { id: 5402 },
    { id: 5403 },
    { id: 5404 },
    { id: 5501 },
    { id: 5502 },
    { id: 5503 },
    { id: 5504 },
  ],
  warehouse_acls: [],
  finance_acls: [],
  pos_acls: [],
};

export const AUDITOR_MENU = {
  setting_acls: [],
  catalog_acls: [
    { id: 2111 },
    { id: 2121 },
    { id: 2131 },
    { id: 2141 },
    { id: 2151 },
    { id: 2161 },
    { id: 2171 },
    { id: 2211 },
    { id: 2221 },
    { id: 2231 },
    { id: 2241 },
    { id: 2251 },
    { id: 2311 },
    { id: 2321 },
    { id: 2411 },
    { id: 2501 },
    { id: 2502 },
  ],
  inventory_acls: [
    { id: 3111 },
    { id: 3211 },
    { id: 3311 },
    { id: 3321 },
    { id: 3331 },
    { id: 3341 },
    { id: 3351 },
    { id: 3411 },
    { id: 3611 },
    { id: 3612 },
    { id: 3613 },
    { id: 3614 },
    { id: 3615 },
    { id: 3616 },
    { id: 3617 },
    { id: 3618 },
    { id: 3619 },
    { id: 3620 },
    { id: 3621 },
    { id: 3622 },
    { id: 3625 },
  ],
  sales_acls: [
    { id: 4101 },
    { id: 4201 },
    { id: 4301 },
    { id: 4401 },
    { id: 4501 },
    { id: 4601 },
    { id: 4602 },
    { id: 4603 },
    { id: 4604 },
    { id: 4605 },
    { id: 4606 },
    { id: 4607 },
    { id: 4608 },
    { id: 4609 },
  ],
  purchase_acls: [
    { id: 5101 },
    { id: 5201 },
    { id: 5301 },
    { id: 5401 },
    { id: 5501 },
    { id: 5502 },
    { id: 5503 },
    { id: 5504 },
  ],
  warehouse_acls: [
    { id: 6111 },
    { id: 6121 },
    { id: 6131 },
    { id: 6141 },
    { id: 6151 },
    { id: 6161 },
    { id: 6211 },
    { id: 6221 },
    { id: 6311 },
    { id: 6321 },
    { id: 6401 },
    { id: 6402 },
    { id: 6403 },
    { id: 6404 },
    { id: 6405 },
    { id: 6406 },
  ],
  finance_acls: [
    { id: 7101 },
    { id: 7201 },
    { id: 7301 },
    { id: 7411 },
    { id: 7421 },
    { id: 7431 },
    { id: 7401 },
    { id: 7501 },
    { id: 7601 },
    { id: 7602 },
    { id: 7603 },
    { id: 7604 },
    { id: 7605 },
    { id: 7606 },
    { id: 7607 },
    { id: 7608 },
    { id: 7609 },
    { id: 7610 },
    { id: 7611 },
    { id: 7612 },
    { id: 7613 },
  ],
  pos_acls: [],
};

export const PICKER_MENU = {
  setting_acls: [],
  catalog_acls: [],
  inventory_acls: [],
  sales_acls: [],
  purchase_acls: [],
  warehouse_acls: [],
  finance_acls: [],
  pos_acls: [],
};

export const POS_ADMINISTRATOR_MENU = {
  setting_acls: [],
  catalog_acls: [],
  inventory_acls: [],
  sales_acls: [],
  purchase_acls: [],
  warehouse_acls: [],
  finance_acls: [],
  pos_acls: [],
};

export const STORE_MANAGER_MENU = {
  setting_acls: [{ id: 9172 }],
  catalog_acls: [
    { id: 2111 },
    { id: 2151 },
    { id: 2221 },
    { id: 2222 },
    { id: 2231 },
    { id: 2232 },
    { id: 2233 },
    { id: 2234 },
    { id: 2241 },
    { id: 2251 },
    { id: 2252 },
    { id: 2321 },
    { id: 2322 },
    { id: 2323 },
    { id: 2324 },
  ],
  inventory_acls: [{ id: 3111 }, { id: 3211 }, { id: 3611 }],
  sales_acls: [{ id: 4201 }, { id: 4301 }, { id: 4401 }, { id: 4402 }, { id: 4606 }, { id: 4609 }, { id: 4801 }],
  purchase_acls: [],
  warehouse_acls: [],
  finance_acls: [],
  pos_acls: [
    { id: 819 },
    { id: 820 },
    { id: 821 },
    { id: 822 },
    { id: 823 },
    { id: 800 },
    { id: 801 },
    { id: 802 },
    { id: 803 },
    { id: 804 },
    { id: 805 },
    { id: 806 },
    { id: 807 },
    { id: 808 },
    { id: 809 },
    { id: 810 },
    { id: 811 },
    { id: 812 },
    { id: 813 },
    { id: 814 },
    { id: 816 },
    { id: 818 },
  ],
};

export const CASHIER_MENU = {
  setting_acls: [],
  catalog_acls: [],
  inventory_acls: [],
  sales_acls: [],
  purchase_acls: [],
  warehouse_acls: [],
  finance_acls: [],
  pos_acls: [{ id: 815 }, { id: 817 }],
};

export const POS_CASHIER_MENU = {
  setting_acls: [],
  catalog_acls: [],
  inventory_acls: [],
  sales_acls: [],
  purchase_acls: [],
  warehouse_acls: [],
  finance_acls: [],
  pos_acls: [{ id: 815 }, { id: 817 }],
};

export const SALES = {
  setting_acls: [],
  catalog_acls: [],
  inventory_acls: [],
  sales_acls: [
    { id: 4101 },
    { id: 4201 },
    { id: 4202 },
    { id: 4203 },
    { id: 4204 },
    { id: 4205 },
    { id: 4301 },
    { id: 4302 },
    { id: 4303 },
    { id: 4304 },
    { id: 4305 },
    { id: 4312 },
    { id: 4401 },
    { id: 4402 },
    { id: 4403 },
    { id: 4404 },
    { id: 4501 },
    { id: 4502 },
    { id: 4503 },
    { id: 4504 },
    { id: 4505 },
    { id: 4601 },
    { id: 4602 },
    { id: 4603 },
    { id: 4604 },
    { id: 4605 },
    { id: 4606 },
    { id: 4607 },
    { id: 4608 },
  ],
  purchase_acls: [],
  warehouse_acls: [],
  finance_acls: [],
  pos_acls: [],
};