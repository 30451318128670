import { useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { ToastVersion } from 'components/utils';
import { useAppSelector } from 'hooks/redux';
import { useServiceWorker } from 'hooks/useServiceWorker';
import { fetcher } from 'lib/fetcher';
import { clearCache } from 'lib/helpers';
import HomePage from 'pages/HomePage';
import Login from 'pages/login';
import PriceCheckerPage from 'pages/pricelist';
import SalesRegister from 'pages/registers';
import MainSetting from 'pages/setting';
import ACLDisplay from 'pages/setting/approve-access';
import DeviceTransaction from 'pages/setting/device-transaction';
import SettingGeneral from 'pages/setting/general';
import Receipt from 'pages/setting/receipt';
import StoreInformation from 'pages/setting/store-information';
// import HomePage from 'pages/HomePage';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AuthInit from 'routes/AuthInit';
import privateRoute from 'routes/list-route';
import { db } from 'services/indexdb/connection';
import LocalStorageService from 'services/localstorage/LocalStorage.service';
import { IPosVersion } from 'types/common.types';

db.open()
  .then(() => console.log('database opened'))
  .catch(function (err) {
    console.error('Failed to open db: ' + (err.stack || err));
  });
const App: React.FC = () => {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const toast = useToast();
  const TWO_MINUTES_REFRESH = 1000 * 60 * 2;
  const isOnline = useAppSelector((state) => state.register.isOnline);

  const { data: versionData } = useQuery<IPosVersion>({
    queryKey: ['version-data'],
    queryFn: () => fetcher('/pos/v3/version?appPlatform=web'),
    refetchInterval: TWO_MINUTES_REFRESH, // 2 minutes
  });

  const handleContextMenu = React.useCallback((event: any) => {
    event.preventDefault();
  }, []);

  React.useEffect(() => {
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  React.useEffect(() => {
    // check for sw updates on page change
    navigator.serviceWorker.getRegistrations().then((regs) => regs.forEach((reg) => reg.update()));
  }, []);

  React.useEffect(() => {
    if (versionData) LocalStorageService.setItem('version', versionData?.version_number);
    if (process.env.NODE_ENV === 'production' && isOnline && showReload && waitingWorker) {
      toast({
        duration: 3000, // 15 second
        isClosable: true,
        status: 'info',
        position: 'top',
        render: () => <ToastVersion onClick={clearCache} text='Versi baru tersedia' />,
      });
    }
  }, [waitingWorker, showReload, reloadPage, versionData]);

  const AuthMenu = privateRoute.map((route, index) => (
    <Route
      key={index}
      path={route.path}
      element={<route.component />}
      index={route.path === 'home' ? true : false}
    />
  ));

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='approve/:uuid' element={<ACLDisplay />} />
        <Route element={<AuthInit />}>
          <Route path='register' element={<SalesRegister />} />
          <Route path='price-checker' element={<PriceCheckerPage />} />
          <Route path='setting' element={<MainSetting />}>
            <Route path='store-info' element={<StoreInformation />} />
            <Route path='receipt' element={<Receipt />} />
            <Route path='other' element={<SettingGeneral />} />
            <Route path='device-transaction' element={<DeviceTransaction />} />
          </Route>
          <Route path='sales' element={<HomePage />}>
            <Route path='*' element={<Navigate to='' replace />} />
            {AuthMenu}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
