/* eslint-disable unused-imports/no-unused-vars */
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Checkbox, Flex, HStack, IconButton, Tag, Text, VStack } from '@chakra-ui/react';
import ListTotal from 'components/list/ListTotal';
import { TooltipPromotion } from 'components/popover/Promotion';
import { Dashed } from 'components/utils';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { IItemCart } from 'types/products.types';
import { ResPromotionTransactionAmount } from 'types/promotion.types';
import { IOrderReturn } from 'types/return.types';
import { ITotalDetail } from 'types/sales.types';
import { currencyFormat, getAllItemsDiscount, getTotalItemsCart } from 'utils';

interface Props {
  showTotal: boolean;
  listItemCart?: IItemCart[];
  transactionPromotion?: ResPromotionTransactionAmount[];
  totalDetail: ITotalDetail;
  isReturn: boolean;
  orderReturn?: IOrderReturn;
  taxIncluded: boolean;
  isOtherFee?: boolean;
  onChangeOtherFee?: (state: boolean) => void;
  onShowTotal?: (state: boolean) => void;
}

const DetailTotal: React.FC<Props> = ({
  isReturn,
  showTotal,
  onShowTotal,
  totalDetail,
  orderReturn,
  taxIncluded,
  transactionPromotion,
  isOtherFee = false,
  onChangeOtherFee,
}: Props) => {
  const sales = useAppSelector((state) => state.sales);
  const isCheckout = useAppSelector((state) => state.return.isCheckout);
  const location = useLocation();
  const inReturnEditor = location.pathname.includes('/sales/return'); // on edit return

  const discountTrx: number =
    Math.abs(totalDetail?.discountTrx ?? 0) + Math.abs(totalDetail?.salesPromotions ?? 0);

  const listPromo = React.useMemo(() => {
    const mapPromotion = sales.listPromotionAmount.map((p) => p.promotion.promotion_name);
    return mapPromotion;
  }, [sales.listPromotionAmount]);

  return (
    <VStack w='full' spacing='8px' px='5px' justifyItems='start' mb={4}>
      {showTotal ? (
        <>
          <ListTotal
            leftText={
              isReturn ? 'Sub Total' : `Sub Total (${getTotalItemsCart(sales.listItemCart ?? [])} Barang)`
            }
            rightText={<Text>{currencyFormat(Math.abs(totalDetail?.subTotalItem ?? 0))}</Text>}
          />
          {!isReturn && getAllItemsDiscount(sales) > 0 ? (
            <ListTotal
              leftText={'Diskon Barang'}
              rightText={<Text color='jubelio.primary'>{currencyFormat(getAllItemsDiscount(sales))}</Text>}
            />
          ) : null}
          {/* For Return */}
          {isReturn && totalDetail.discountBarang !== 0 ? (
            <ListTotal
              leftText={'Diskon Barang'}
              rightText={
                <Text color='jubelio.primary'>
                  {currencyFormat(Math.abs(totalDetail?.discountBarang ?? 0))}
                </Text>
              }
            />
          ) : null}
          {!isReturn && totalDetail.discountOutlet > 0 ? (
            <ListTotal
              leftText={'Diskon Outlet'}
              rightText={
                <Text color='jubelio.primary'>{currencyFormat(totalDetail?.discountOutlet ?? 0)}</Text>
              }
            />
          ) : null}
          {Math.abs(discountTrx) > 0 ? (
            <ListTotal
              leftText={
                <HStack>
                  <span>Diskon Transaksi</span>
                  {listPromo && listPromo.length > 0 && (
                    <TooltipPromotion placement='right' listPromotion={listPromo} />
                  )}
                </HStack>
              }
              rightText={<Text color='jubelio.primary'>{currencyFormat(discountTrx)}</Text>}
            />
          ) : null}
          {Math.abs(totalDetail.totalTax) > 0 && (
            <ListTotal
              leftText={
                <HStack>
                  <Text>Pajak</Text>
                  {taxIncluded === true ? (
                    <Tag
                      bg='accent.200'
                      color='accent.400'
                      fontWeight='semibold'
                      variant='solid'
                      size='sm'
                      borderRadius='4px'
                    >
                      Harga Termasuk Pajak
                    </Tag>
                  ) : null}
                </HStack>
              }
              rightText={currencyFormat(Math.abs(totalDetail.totalTax ?? 0))}
            />
          )}
          {Math.abs(totalDetail.otherCost) > 0 && (
            <ListTotal
              leftText={'Biaya Lainnya'}
              rightText={<Text>{currencyFormat(Math.abs(totalDetail.otherCost ?? 0))}</Text>}
            />
          )}
          {totalDetail.shippingCost > 0 && (
            <ListTotal
              leftText={'Ongkos Kirim'}
              rightText={<Text>{currencyFormat(totalDetail.shippingCost ?? 0)}</Text>}
            />
          )}
          {totalDetail.insuranceCost > 0 && (
            <ListTotal
              leftText={'Asuransi Pengiriman'}
              rightText={<Text>{currencyFormat(totalDetail.insuranceCost ?? 0)}</Text>}
            />
          )}
          {Math.abs(totalDetail.roundMoney) > 0 && (
            <ListTotal
              leftText={'Pembulatan'}
              rightText={<Text>{currencyFormat(Math.abs(totalDetail.roundMoney ?? 0))}</Text>}
            />
          )}
          {isReturn && totalDetail.totalItems !== 0 && (
            <ListTotal
              leftText={'Total Barang'}
              rightText={<Text>{Math.abs(totalDetail.totalItems ?? 0)}</Text>}
            />
          )}
          {/* disable temporary until tested by QA */}
          {!isCheckout && isReturn && inReturnEditor && orderReturn?.add_fee !== 0 && (
            <ListTotal
              leftText={
                <Flex gap={3}>
                  <Text as='label' fontSize='sm' color='jubelio.primary'>
                    (Hapus Biaya Lain & Pembulatan)
                  </Text>
                  <Checkbox
                    isChecked={isOtherFee}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeOtherFee && onChangeOtherFee(e.target.checked)
                    }
                    colorScheme='red'
                  />
                </Flex>
              }
              rightText=''
            />
          )}

          <Dashed w='full' />
        </>
      ) : null}
      <ListTotal
        leftText={!isReturn ? 'Total Bayar' : 'Total Pengembalian'}
        pt='5px'
        rightText={
          <HStack>
            <Text color='jubelio.primary' fontWeight='bold'>
              {isReturn
                ? currencyFormat(Number(Math.abs(totalDetail.haveToPay ?? 0)))
                : currencyFormat(totalDetail.grandTotal ?? 0)}
            </Text>
            {onShowTotal ? (
              <IconButton
                variant='ghost'
                aria-label='add to shopping cart'
                _focus={{ boxShadow: 'none' }}
                onClick={() => onShowTotal && onShowTotal(!showTotal)}
                icon={showTotal ? <ChevronDownIcon h='20px' w='20px' /> : <ChevronUpIcon h='20px' w='20px' />}
                size='sm'
              />
            ) : null}
          </HStack>
        }
      />
    </VStack>
  );
};

export default DetailTotal;
