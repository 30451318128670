import Dexie from 'dexie';
import {
  IAuthorizedUser,
  IContactCategory,
  ICourier,
  ICustomerInfo,
  IMarketplaceIntegration,
  IPresets,
} from 'types/common.types';
import { IListStockV2, IProductList } from 'types/products.types';

/**
 * IndexedDB connection
 * @class
 * @classdesc IndexedDB connection
 * @implements {IConnection}
 * @property {Dexie} db - IndexedDB database
 * @property {string} dbName - IndexedDB database name
 * @property {string} dbVersion - IndexedDB database version
 * @property {string} dbStore - IndexedDB database store
 * @property {string} dbStorePresets - IndexedDB database store for presets
 * @property {string} dbStoreProducts - IndexedDB database store for products
 * @property {string} dbStoreCustomers - IndexedDB database store for customers
 * @property {string} dbStoreSettings - IndexedDB database store for settings
 */
export class DbConnection extends Dexie {
  products: Dexie.Table<IProductList, number>;
  presetsdiscount: Dexie.Table<IPresets, number>;
  presetstax: Dexie.Table<IPresets, number>;
  customer: Dexie.Table<ICustomerInfo, number>;
  salesmen: Dexie.Table<any, number>;
  discount: Dexie.Table<any, number>;
  promotion: Dexie.Table<any, number>;
  promotionitems: Dexie.Table<any, number>;
  paymentmethod: Dexie.Table<any, number>;
  order: Dexie.Table<any, number>;
  return: Dexie.Table<any, number>;
  cart: Dexie.Table<any, number>;
  pricebook: Dexie.Table<any, number>;
  serialnumber: Dexie.Table<any, number>;
  batchnumber: Dexie.Table<any, number>;
  courier: Dexie.Table<ICourier, number>;
  contactcategory: Dexie.Table<IContactCategory, number>;
  stock: Dexie.Table<IListStockV2, number>;
  marketplace: Dexie.Table<IMarketplaceIntegration, number>;
  authorizeduser: Dexie.Table<IAuthorizedUser, number>;

  constructor() {
    super('jubelio-pos');
    this.version(18).stores({
      items:
        '++keypath, item_group_id, item_name, *variants.item_code, *variations.barcode, *variants.item_name',
      customer: '++keypath, contact_id, email, phone',
      salesmen: '++keypath, salesmen_id',
      promotion: '++keypath, promotion_id, promotion_type, *items',
      promotionitems: '++keypath, id_type, promotion_item_id, item_id, item_type',
      paymentmethod: 'keypath++, payment_id, payment_type',
      presetstax: 'pos_preset_id, preset_amount',
      presetsdiscount: 'pos_preset_id, preset_amount',
      order:
        '++key,salesorder_id, salesorder_no, pos_return_no, *items, is_paid, is_canceled, *payments, transaction_date, is_sending',
      return:
        '++key,salesorder_id, salesorder_no, pos_return_id, pos_return_no, *items, is_paid, is_canceled, transaction_date, is_sending',
      cart: '++id,name,note',
      discount: '++key, discount_detail_id',
      pricebook: '++key, price_book_detail_id, item_id, item_group_id',
      serialnumber: '++key, serial_no, item_id',
      batchnumber: '++key, batch_no, item_id',
      courier: '++keypath, courier_id',
      contactcategory: '++keypath, category_id',
      stock: '++keypath, item_group_id, item_id, qty',
      marketplace: '++keypath, channel_id',
      authorizeduser: '++keypath, user_id, permission_id',
    });

    this.products = this.table('items');
    this.presetsdiscount = this.table('presetsdiscount');
    this.presetstax = this.table('presetstax');
    this.customer = this.table('customer');
    this.salesmen = this.table('salesmen');
    this.cart = this.table('cart');
    this.discount = this.table('discount');
    this.promotion = this.table('promotion');
    this.promotionitems = this.table('promotionitems');
    this.paymentmethod = this.table('paymentmethod');
    this.order = this.table('order');
    this.return = this.table('return');
    this.pricebook = this.table('pricebook');
    this.serialnumber = this.table('serialnumber');
    this.batchnumber = this.table('batchnumber');
    this.courier = this.table('courier');
    this.contactcategory = this.table('contactcategory');
    this.stock = this.table('stock');
    this.marketplace = this.table('marketplace');
    this.authorizeduser = this.table('authorizeduser');
  }
}
export const db = new DbConnection();
