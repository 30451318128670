import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  LinkBox,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import BadgeProduct from 'components/badge/BadgeProduct';
import { PrintIcon } from 'components/icons';
import ClosureToPrint from 'components/receipt/ClosureToPrint';
import LoadingCashier from 'components/state/LoadingCashier';
import { useNotification, usePrint } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ErrorHandler } from 'lib/error-handler';
import * as React from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  getTakeNotes,
  resetClosure,
  saveBanksNote,
  savePaymentLists,
  setItemSold,
} from 'redux/reducer/closure';
import { setAsCashier, setCurrentClosure, setRegisterInfo } from 'redux/reducer/registers';
import { resetOrder } from 'redux/reducer/sales';
import { setStructSetting } from 'redux/reducer/settings';
import { setFreshchatCustomConfig } from 'redux/reducer/settings';
import closureRequest from 'services/http/closure.request';
import commonRequest from 'services/http/common.request';
import { RegisterFragment, RegisterObject } from 'types/register.types';
import { formatDate } from 'utils';
import { clossureCommand } from 'utils/printer/closure-command';

interface CardProps {
  register: RegisterObject;
  selectedRegister: RegisterFragment;
  loadingRegister: boolean;
  onClick: (value: unknown) => void;
}

const CardRegister: React.FC<CardProps> = (props: CardProps) => {
  const { register, selectedRegister, loadingRegister, onClick } = props;
  const userInfo = useAppSelector((state) => state.auth.profile);
  const isUsed = register.status === 'closed' || register.opened_by === userInfo?.email;
  const { printer } = useAppSelector((state) => state.commons);
  const { location } = useAppSelector((state) => state.register);
  const [loadingPrintClosure, setLoadingPrintClosure] = React.useState<boolean>(false);
  const componentRef = React.useRef(null);
  const dispatch = useAppDispatch();
  const { notification } = useNotification();

  const getDetailPayment = async (paymentId: number, closureId: number) => {
    try {
      const res = await closureRequest.transactionPayment(closureId, paymentId);

      const totalRetur: number = res.data
        .filter((i) => i.transaction_type === 'Retur')
        .reduce((total, item) => total + Number(item.payment_amount), 0);

      const totalSales: number = res.data
        .filter((i) => i.transaction_type === 'Penjualan')
        .reduce((total, item) => total + Number(item.payment_amount), 0);

      return { totalRetur, totalSales };
    } catch (error) {
      const errorRes = ErrorHandler(error, 'detail-payments');
      notification('', errorRes.message, 'error');
    }
  };

  const getPrintLastClosure = async (register: RegisterObject) => {
    setLoadingPrintClosure(true);
    const resultClosure = await closureRequest.listPaymentClosure(register.last_closure.closure_id);
    const resultStruct = await commonRequest.structPrint(register.location_id);
    if (resultClosure && resultStruct) {
      const joinItems = [...resultClosure.totalQtyItemsBundle, ...resultClosure.totalQtyItemsNonBundle];
      const itemSold = joinItems.map((val) => ({
        ...val,
        sales_qty: Number(val.sales_qty),
        return_qty: Number(val.return_qty),
      }));

      const afterSort = resultClosure.listPayments
        ? resultClosure.listPayments.sort((a, b) => Number(a.key) - Number(b.key))
        : [];

      const updatedPayments = await Promise.all(
        afterSort.map(async (payment) => {
          const res = await getDetailPayment(payment.payment_id, register.last_closure.closure_id);
          return {
            ...payment,
            total_sales: res?.totalSales ?? 0,
            total_retur: res?.totalRetur ?? 0,
          };
        })
      );

      const currentClosurePrint = {
        closure_id: register.last_closure.closure_id,
        register_id: register.register_id,
        closing_date: register.last_closure.closing_date,
        open_date: register.date_opened,
        cash_initial: String(register.last_closure.cash_initial ?? 0),
        username: resultClosure.cashierClosure?.full_name ?? '',
      };

      const closureData = {
        listItemSold: itemSold,
        listPayments: updatedPayments,
        listBankNotes: resultClosure.listBanknotes,
        closureId: register.last_closure.closure_id as number,
        openDate: register.date_opened as Date,
        initCash: Number(register.last_closure.cash_initial ?? 0),
        notes: register.last_closure.notes ?? '',
        closingDate: register.last_closure.closing_date as Date,
      };

      dispatch(setStructSetting(resultStruct));
      dispatch(setRegisterInfo(register));
      dispatch(savePaymentLists(updatedPayments));
      dispatch(saveBanksNote(resultClosure.listBanknotes));
      dispatch(setItemSold(itemSold));
      dispatch(getTakeNotes(register.last_closure.notes ?? ''));
      dispatch(setCurrentClosure(currentClosurePrint));

      const data = await clossureCommand({
        closureData,
        settingPrint: resultStruct,
        location,
        profile: userInfo,
        paperSize: Number(printer?.paperSize),
        printCopy: printer?.printCopy,
        reprint: resultClosure.cashierClosure?.full_name ?? '',
        registerName: register?.register_name ?? '',
      });

      usePrint({
        dataPrint: data,
        onErroPrint: () => handleReactToPrint(),
        onIgnorePrint: () => handleReactToPrint(),
        onSuccessPrint: () => {
          resetState();
        },
      });
    }
  };

  const resetState = () => {
    dispatch(resetOrder());
    dispatch(resetClosure());
    dispatch(setAsCashier(false));
    dispatch(setCurrentClosure(null));
    dispatch(setRegisterInfo(null));
    dispatch(setFreshchatCustomConfig(false));
    dispatch(getTakeNotes(''));
    setLoadingPrintClosure(false);
  };

  const handleReactToPrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      resetState();
    },
  });

  return (
    <Box position='relative'>
      {selectedRegister.register_id === register.register_id && loadingRegister && <LoadingCashier />}
      <LinkBox
        bg='white'
        color='gray.600'
        px={4}
        py={4}
        rounded='4px'
        role='group'
        border='1px'
        borderColor='gray.100'
        boxShadow='main-card'
        data-test-register={register.register_id}
        transform='auto-gpu'
        transitionDuration='fast'
        transitionProperty='common'
        transitionTimingFunction='ease-out'
        _hover={{
          boxShadow: isUsed ? 'lg' : '',
        }}
        opacity={isUsed ? '1' : '0.7'}
      >
        <Stack>
          <Flex justifyContent='space-between' alignItems='center' mb={2}>
            <Box>
              <Heading
                mb='0'
                fontSize={['12px', '12px', '12px', '13px', '14px']}
                fontWeight='700'
                pb={!register.opened_by ? 6 : 0}
              >{`${register.register_name}`}</Heading>
              <Text fontSize='12px' as='span'>
                {register.opened_by}
              </Text>
            </Box>
            {register.status !== 'open' && register.last_closure && (
              <IconButton
                aria-label='print'
                icon={<PrintIcon fill='jubelio.grey200' />}
                variant='outline'
                isLoading={loadingPrintClosure}
                isDisabled={loadingPrintClosure}
                size='sm'
                onClick={() => getPrintLastClosure(register)}
              />
            )}
          </Flex>
          <Divider />
          <Flex justifyContent='space-between' alignItems='center' mb={3}>
            <VStack textAlign='left' alignItems='flex-start'>
              <Text fontSize='12px' as='span'>
                {register.date_opened !== null ? formatDate(register.date_opened) : 'Belum pernah dibuka'}
              </Text>
              <Text fontSize='12px' as='span'>
                Device:{' '}
                {register.device_info
                  ? register.device_info?.device_type !== 'WEB'
                    ? register.device_info.device_type + ' - ' + register.device_info.device_name
                    : register.device_info.device_name
                  : '-'}
              </Text>
            </VStack>
            <BadgeProduct mb='0px' colorScheme={register.status === 'open' ? 'green' : 'red'}>
              {register.status === 'open' ? 'In Used' : 'Deactive'}
            </BadgeProduct>
          </Flex>
          <Button
            variant='outline'
            onClick={() => onClick(register)}
            size='sm'
            isDisabled={!isUsed || loadingPrintClosure}
            border='1px'
            borderColor={register.status === 'open' ? 'gray.300' : 'gray.200'}
          >
            Buka Kasir
          </Button>
        </Stack>
      </LinkBox>
      <div className='hidden'>
        <ClosureToPrint ref={componentRef} />
      </div>
    </Box>
  );
};

export default CardRegister;
