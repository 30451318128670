import { IInvoice, IReportInvoice } from 'types/invoice';

import request from './request';

export default {
  getInvoice: async (qs: string): Promise<IReportInvoice> => {
    try {
      const res = await request.get(`/reports/invoice/?${qs}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  sendInvoice: async (salesorder_id: number): Promise<IInvoice> => {
    try {
      const res = await request.post(`/sales/packlists/create-invoice`, { salesorder_id });
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
